import React, { useState, useEffect } from 'react';
import LgbtGaleria from '../../components/lgbtGaleria';
import { FaTimes } from 'react-icons/fa';
import './styles.css';

const TabsComponent = ({ data }) => {
    const [activeTab, setActiveTab] = useState(data[0]?.label || '');
    const [selectedImage, setSelectedImage] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Función para abrir el modal con la imagen y sus detalles
    const openModal = (image) => {
        setSelectedImage(image);
        setIsModalOpen(true);
    };

    // Función para cerrar el modal
    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedImage(null);
    };

    // Efecto para mantener el tab activo al cambiar el idioma
    useEffect(() => {
        if (!data.some(tab => tab.label === activeTab)) {
            setActiveTab(data[0]?.label || ''); // Si el tab activo no está en los nuevos datos, selecciona el primero
        }
    }, [data, activeTab]);

    // Filtrar la galería del tab "LGBT"
    const lgbtTab = data.find(tab => tab.label === 'LGBT');
    const lgbtGaleria = lgbtTab ? lgbtTab.galeria : [];

    return (
        <div className="lgbt-tabs-container">
            {/* Renderizado de los tabs */}
            <div className="lgbt-tabs-header">
                {data.map((tab, index) => (
                    <button
                        key={index}
                        className={`lgbt-tab-button ${activeTab === tab.label ? 'active' : ''}`}
                        onClick={() => setActiveTab(tab.label)}
                    >
                        {tab.label}
                    </button>
                ))}
            </div>

            {/* Renderizado del contenido del tab activo */}
            <div className="lgbt-tab-content">
                {data.map((tab, index) => (
                    activeTab === tab.label && (
                        <div key={index}>
                            <p className="lgbt-tab-description">{tab.description}</p>
                            <div className="lgbt-images-flex">
                                {tab.images.map((image, imgIndex) => (
                                    <div 
                                        key={imgIndex} 
                                        className={`lgbt-image-link ${imgIndex === 0 ? 'full-width' : ''}`} 
                                        onClick={() => {
                                            if (image.type === 'link') {
                                                window.open(image.link, '_blank');
                                            } else if (image.type === 'image') {
                                                openModal(image);
                                            }
                                        }}
                                    >
                                        <div className="lgbt-image-container">
                                            <img src={require(`../../assets/img/experiences/wedding/${image.src}`)} alt={image.alt} className="lgbt-collage-image" />
                                            <div className="lgbt-image-overlay">
                                                <span className="lgbt-image-text">{image.text}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            {/* Contenido exclusivo para el tab LGBT */}
                            {tab.label === 'LGBT' && (
                                <div className="extra-lgbt-content">
                                    <div className="cinta-roja mt-30">
                                        <img
                                            src={require(`../../assets/img/experiences/wedding/${tab.cinta}`)}
                                            alt="separador"
                                            className="cinta-roja-image"
                                        />
                                    </div>
                                    <h2 className='text-center lgbt-h2'>{tab.titleLgbt}</h2>
                                    <p className='text-center'>{tab.p1Lgbt}</p>
                                    <p className='text-center'>{tab.p2Lgbt}</p>
                                    <LgbtGaleria data={lgbtGaleria} />
                                </div>
                            )}
                        </div>
                    )
                ))}
            </div>

            {/* Modal */}
            {isModalOpen && (
                <div className="lgbt-modal-overlay" onClick={closeModal}>
                    <div className="lgbt-modal-content" onClick={e => e.stopPropagation()}>
                        <div className="lgbt-modal-image">
                            <img src={require(`../../assets/img/experiences/wedding/${selectedImage.src}`)} alt={selectedImage.alt} className="lgbt-modal-img" />
                        </div>
                        <div className="lgbt-modal-details">
                            <h2 className="lgbt-modal-title">{selectedImage.text}</h2>
                            <p className="lgbt-modal-description">{selectedImage.descripcion}</p>
                        </div>
                        <button className="lgbt-modal-close" onClick={closeModal}>
                            <FaTimes />
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TabsComponent;