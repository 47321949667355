import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Context } from '../../Context';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

import geoparquesEs from '../../assets/data/geoparques/es/index.json';
import geoparquesDe from '../../assets/data/geoparques/de/index.json';
import geoparquesFr from '../../assets/data/geoparques/fr/index.json';
import geoparquesEn from '../../assets/data/geoparques/en/index.json';
import geoparquesIt from '../../assets/data/geoparques/it/index.json';
import geoparquesPt from '../../assets/data/geoparques/pt/index.json';
import geoparquesJp from '../../assets/data/geoparques/jp/index.json';
import geoparquesCn from '../../assets/data/geoparques/cn/index.json';


import './styles.css';

import BarriosMagicosVideoSlider from '../../components/barriosMagicosVideoSlider';
import MetaTags from '../../components/metaTags';
import GeoparquesHeroSlider from '../../components/geoparquesHeroSlider';
import GeoparquesHeader from '../../components/geoparquesHeader';
import GeoParquesInfo from '../../components/geoparquesInfo';
import GeoparquesPilares from '../../components/geoparquesPilares';
import GeoParquesMexico from '../../components/geoparquesMexico';
import GeoparqueSingle from '../../components/geoparqueSingle';
import GeoparqueOaxaca from '../../components/geoparqueMixteca';
import GeoparqueCoahuila from '../../components/geoparqueCoahuila';
import GeoparquesDescarga from '../../components/geoparquesDescarga';
import GeoparquesCinta from '../../components/geoparquesCinta';

const Geoparques = () => {
    const { isLanguage } = useContext(Context);
    const [language, setLanguage] = useState(geoparquesEs);

    window?.scroll({
        top: 0
    });

    useEffect(() => {
        isLanguage === 'ES' ? setLanguage(geoparquesEs)
            : isLanguage === 'DE' ? setLanguage(geoparquesDe)
            : isLanguage === 'FR' ? setLanguage(geoparquesFr)
            : isLanguage === 'EN' ? setLanguage(geoparquesEn)
            : isLanguage === 'IT' ? setLanguage(geoparquesIt)
            : isLanguage === 'PT' ? setLanguage(geoparquesPt)
            : isLanguage === 'JP' ? setLanguage(geoparquesJp)
            : isLanguage === 'CN' ? setLanguage(geoparquesCn)
            : setLanguage(geoparquesEs)
    }, [isLanguage]);

    const metaInfo = {
        'ES': {
            title: 'Geoparques - VisitMexico',
            description: 'Explora los Barrios Mágicos de México. Descubre la cultura, historia y encanto único de cada barrio'
        },
        'EN': {
            title: 'Magic Neighborhoods in Mexico - VisitMexico',
            description: 'Explore the Magic Neighborhoods of Mexico. Discover the culture, history and unique charm of each neighborhood'
        },
        'FR': {
            title: 'Quartiers magiques au Mexique - VisitMexico',
            description: 'Explorez les Quartiers Magiques du Mexique. Découvrez la culture, l\'histoire et le charme unique de chaque quartier.">'
        },
        'DE': {
            title: 'Magische Viertel in Mexiko - VisitMexico',
            description: 'Entdecken Sie die magischen Viertel Mexikos. Entdecken Sie die Kultur, Geschichte und den einzigartigen Charme jedes Viertels'
        },
        'PT': {
            title: 'Bairros Mágicos no México - VisitMexico',
            description: 'Explore os Bairros Mágicos do México. Descubra a cultura, história e charme único de cada bairro'
        },
        'IT': {
            title: 'Quartieri magici in Messico - VisitMexico',
            description: 'Esplora i quartieri magici del Messico. Scopri la cultura, la storia e il fascino unico di ogni quartiere'
        },
        'JP': {
            title: 'メキシコのマジカルネイバーフッド - VisitMexico',
            description: 'メキシコのマジカルネイバーフッドを探索しよう。各地区の文化、歴史、ユニークな魅力を発見しよう'
        },
        'CN': {
            title: '墨西哥的魔法街区 - VisitMexico',
            description: '探索墨西哥的魔法街区。发现每个街区的文化、历史和独特魅力。'
        }
    }

    return (
        <div className="main-content">
            <MetaTags title={metaInfo[isLanguage].title} description={metaInfo[isLanguage].description} />
            <GeoparquesHeroSlider data={language.heroSlide} />
            <GeoparquesCinta data={language} />
            <LazyLoadComponent>
                <GeoparquesHeader data={language} />
                <GeoParquesInfo data={language} />
                <GeoparquesPilares data={language} />
                <GeoparquesCinta data={language} />
                <GeoParquesMexico data={language} />
                <GeoparquesCinta data={language} />
                <GeoparqueSingle data={language} />
                <GeoparquesHeroSlider data={language.hidalgoSlide} />
                <GeoparquesCinta data={language} />
                <GeoparqueOaxaca data={language} />
                <GeoparquesHeroSlider data={language.oaxacaSlide} />
                <GeoparquesCinta data={language} />
                <GeoparqueCoahuila data={language} />
                <GeoparquesHeroSlider data={language.coahuilaSlide} />
                <GeoparquesDescarga data={language} />
               
            </LazyLoadComponent>
        </div>
    );
};

export default Geoparques;
