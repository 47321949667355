export const infoCn = [
  {
    image: "tren-maya-cover.jpg",
    title: "Tren Maya",
    description: [
      {
        text: "Tren Maya",
      },
    ],
    urlTitle: "视频",
    itemType: "video",
    url: "https://www.youtube.com/embed/OzJNdv4iJe0?rel=0&rel=0&autoplay=1&mute=0&loop=1",
  },

  {
    image: "homeSlide1.jpg",
    title: "México através de los años",
    description: [
      {
        text: "百年墨西哥",
      },
      {
        text: "欣赏它！",
      },
    ],
    urlTitle: "视频",
    itemType: "video",
    url: "https://www.youtube.com/embed/_e5j176HVQc?rel=0&autoplay=1&mute=0&loop=1",
  },
  {
    image: "homeSlide2.jpg",
    title: "Descubre sitios arquelógicos",
    description: [
      {
        text: "发现考古遗址保护墨西哥的过去",
      },
    ],
    urlTitle: "视频",
    itemType: "video",
    url: "https://www.youtube.com/embed/vyj7S5IoX1Q?rel=0&autoplay=1&mute=0&loop=1",
  },

  {
    image: "homeSlide3.jpg",
    title: "Nuevos nombramientos, pueblos mágicos",
    description: [
      {
        text: "新新名单",
      },
      {
        text: "2023墨西哥旅游节",
      },
    ],
    urlTitle: "查看",
    itemType: "enlace",
    url: "/pueblos-magicos",
  },
  {
    image: "homeSlide4.jpg",
    title: "Explora los Barrios Mágicos:",
    description: [
      {
        text: "探索 Barrios Mágicos",
      },
      {
        text: "新任命",
      },
    ],
    urlTitle: "查看",
    itemType: "enlace",
    url: "/barrios-magicos",
  },
];
