import React, { useState } from 'react';
import './styles.css'; // Importa el archivo CSS

const GeoparquesPilares = ({ data }) => {
  // Añadir un estado para manejar cuál tarjeta está volteada
  const [flipped, setFlipped] = useState(Array(data.pilares.length).fill(false));

  const handleFlip = (index) => {
    const newFlipped = [...flipped];
    newFlipped[index] = !newFlipped[index]; // Cambiar el estado de la tarjeta específica
    setFlipped(newFlipped);
  };

  return (
    <>
      <h2 className="geoparque-header-title mt-30">{data?.pilaresTitle}</h2>
      <div className="geoparquesPilares_container">
        <div className="geoparquesPilares_row">
          {data.pilares.map((pilar, index) => (
            <div className="geoparquesPilares_col" key={index}>
              <div
                className={`geoparquesPilares_card ${flipped[index] ? 'flipped' : ''}`}
                onClick={() => handleFlip(index)} // Voltea la tarjeta al hacer clic
              >
                {/* Parte delantera */}
                <div className="geoparquesPilares_card-front">
                  <img
                    src={require(`../../assets/img/${pilar.pilarIcono}`)}
                    alt={pilar.pilarTitulo}
                    className="geoparquesPilares_icon"
                  />
                  <h3 className="geoparquesPilares_title">{pilar.pilarTitulo}</h3>
                </div>

                {/* Parte trasera */}
                <div className="geoparquesPilares_card-back">
                  <p className="geoparquesPilares_description">
                    {pilar.pilarDescripcion}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default GeoparquesPilares;