import React from 'react';
import './styles.css';

const GeoparqueOaxaca = ({ data }) => {
    return (
      <div className="geoparqueSingle-container">
        <h2 className="geoparqueSingle-header-title">{data?.Oaxacatitulo}</h2>
        <p className="geoparqueSingle-paragraph">{data?.Oaxacaparrafo1}</p>
        
        <ul className="geoparqueSingle-list">
          {data?.OaxacaLista?.map((pueblo, index) => (
            <li key={index} className="geoparqueSingle-list-item">{pueblo}</li>
          ))}
        </ul>
        <p className="geoparqueSingle-paragraph">{data?.Oaxacaparrafo2}</p>
       
      </div>
    );
  };
  
  export default GeoparqueOaxaca;