export const infoDe = [
  {
    image: "tren-maya-cover.jpg",
    title: "Tren Maya",
    description: [
      {
        text: "Tren Maya",
      },
    ],
    urlTitle: "Mehr erfahren",
    itemType: "video",
    url: "https://www.youtube.com/embed/OzJNdv4iJe0?rel=0&rel=0&autoplay=1&mute=0&loop=1",
  },

  {
    image: "homeSlide1.jpg",
    title: "México através de los años",
    description: [
      {
        text: "Mexiko im Laufe der Jahre.",
      },
      {
        text: "Sehen Sie sich das an!",
      },
    ],
    urlTitle: "Mehr erfahren",
    itemType: "video",
    url: "https://www.youtube.com/embed/21cDYP2C9R4?rel=0&autoplay=1&mute=0&loop=1",
  },
  {
    image: "homeSlide2.jpg",
    title: "Descubre sitios arquelógicos",
    description: [
      {
        text: "Entdecken Sie die archäologischen Stätten, ",
      },
      {
        text: "die Mexikos Vergangenheit bewahren",
      },
    ],
    urlTitle: "Mehr erfahren",
    itemType: "video",
    url: "https://www.youtube.com/embed/vyj7S5IoX1Q?rel=0&autoplay=1&mute=0&loop=1",
  },

  {
    image: "homeSlide3.jpg",
    title: "Nuevos nombramientos, pueblos mágicos",
    description: [
      {
        text: "Pueblos Mágicos 2023: ",
      },
      {
        text: "neue Nominierungen",
      },
    ],
    urlTitle: "Mehr erfahren",
    itemType: "enlace",
    url: "/pueblos-magicos",
  },
  {
    image: "homeSlide4.jpg",
    title: "Explora los Barrios Mágicos:",
    description: [
      {
        text: "Erkunde Barrios Mágicos:",
      },
      {
        text: "Neue Benennung",
      },
    ],
    urlTitle: "Mehr erfahren",
    itemType: "enlace",
    url: "/barrios-magicos",
  },
];
