export const infoEn = [
  {
    image: "tren-maya-cover.jpg",
    title: "Tren Maya",
    description: [
      {
        text: "Tren Maya",
      },
    ],
    urlTitle: "VIDEO",
    itemType: "video",
    url: "https://www.youtube.com/embed/OzJNdv4iJe0?rel=0&rel=0&autoplay=1&mute=0&loop=1",
  },

  {
    image: "homeSlide1.jpg",
    title: "México através de los años",
    description: [
      {
        text: "Mexico through the years",
      },
      {
        text: "Marvel at it!",
      },
    ],
    urlTitle: "VIDEO",
    itemType: "video",
    url: "https://www.youtube.com/embed/21cDYP2C9R4?rel=0&autoplay=1&mute=0&loop=1",
  },
  {
    image: "homeSlide2.jpg",
    title: "Descubre sitios arquelógicos",
    description: [
      {
        text: "Discover the archeological wonders",
      },
      {
        text: "that guard the Mexican history",
      },
    ],
    urlTitle: "VIDEO",
    itemType: "video",
    url: "https://www.youtube.com/embed/DpYDJs-6HI8?rel=0&autoplay=1&mute=0&loop=1",
  },

  {
    image: "homeSlide3.jpg",
    title: "Nuevos nombramientos, pueblos mágicos",
    description: [
      {
        text: "Pueblos Mágicos 2023:",
      },
      {
        text: "new names",
      },
    ],
    urlTitle: "View More",
    itemType: "enlace",
    url: "/pueblos-magicos",
  },
  {
    image: "homeSlide4.jpg",
    title: "Explora los Barrios Mágicos:",
    description: [
      {
        text: "Explore the Barrios Mágicos: ",
      },
      {
        text: "new names",
      },
    ],
    urlTitle: "See More",
    itemType: "enlace",
    url: "/barrios-magicos",
  },
];
