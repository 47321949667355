import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Context } from '../../Context';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

import muertosEs from '../../assets/data/muertos/es/index.json';
import muertosDe from '../../assets/data/muertos/de/index.json';
import muertosFr from '../../assets/data/muertos/fr/index.json';
import muertosEn from '../../assets/data/muertos/en/index.json';
import muertosIt from '../../assets/data/muertos/it/index.json';
import muertosPt from '../../assets/data/muertos/pt/index.json';
import muertosJp from '../../assets/data/muertos/jp/index.json';
import muertosCn from '../../assets/data/muertos/cn/index.json';
import './styles.css';
import CiuadesPatrimonioHeroSlider from '../../components/ciudadesPatrimonioHeroSlider';
import MetaTags from '../../components/metaTags';
import MuertosCarrusel from '../../components/muertosCarrusel';
import MuertosInfo from '../../components/muertosInfo';
import MuertosEventos from '../../components/muertosEventos';
import MuertosDestinos from '../../components/muertosDestinos';
import MuertosHeader from '../../components/muertosHeader';
import MuertosCinta from '../../components/muertosCinta';
import MuertosVideoSlider from '../../components/muertosVideoSlider';
import MuertosFooter from '../../components/muertosFooter';


import MuertosVideo from '../../components/muertosVideo';
import MuertosMapa2 from '../../components/muertosMapa2';
import MuertosAltar2 from '../../components/muertosAltar2';


const Muertos = () => {
    const { isLanguage } = useContext(Context);
    const [language, setLanguage] = useState(muertosEs);

    window?.scroll({
        top: 0
    });

    useEffect(() => {
        isLanguage === 'ES' ? setLanguage(muertosEs)
            : isLanguage === 'DE' ? setLanguage(muertosDe)
            : isLanguage === 'FR' ? setLanguage(muertosFr)
            : isLanguage === 'EN' ? setLanguage(muertosEn)
            : isLanguage === 'IT' ? setLanguage(muertosIt)
            : isLanguage === 'PT' ? setLanguage(muertosPt)
            : isLanguage === 'JP' ? setLanguage(muertosJp)
            : isLanguage === 'CN' ? setLanguage(muertosCn)
            : setLanguage(muertosEs)
    }, [isLanguage]);

  const metaInfo = {
    'ES': {
      title: 'Descubre el Día de Muertos en México | Tradiciones, Altares y Celebraciones',
    description: 'Explora el Día de Muertos en México, una de las tradiciones más emblemáticas del país. Descubre los altares, ofrendas, y las festividades llenas de color que honran a nuestros seres queridos.',
    keywords: "Día de Muertos, México, Tradiciones, Altares, Ofrendas, Celebraciones, Cultura Mexicana, Festividades Mexicanas",
    author: "Visit México", 
    robots: "index, follow", 
    ogTitle: "Día de Muertos en México | Tradiciones, Altares y Celebraciones",
    ogDescription: "Sumérgete en la magia del Día de Muertos en México. Conoce las tradiciones, altares y eventos que celebran la vida y honran a los seres queridos.",
    ogImage: "visitmexico.com/images/dia-de-muertos.jpg",
    ogUrl: "https://www.visitmexico.com/dia-de-muertos", 
    ogType: "website" 
    },
    'EN': {
      title: 'Discover the Día de Muertos in Mexico | Traditions, Altars, and Celebrations',
description: 'Explore the Día de Muertos in Mexico, one of the country’s most emblematic traditions. Discover the altars, offerings, and colorful festivities that honor our loved ones.',
keywords: "Día de Muertos, Mexico, Traditions, Altars, Offerings, Celebrations, Mexican Culture, Mexican Festivities",
author: "Visit Mexico", 
robots: "index, follow", 
ogTitle: "Día de Muertos in Mexico | Traditions, Altars, and Celebrations",
ogDescription: "Immerse yourself in the magic of the Día de Muertos in Mexico. Discover the traditions, altars, and events that celebrate life and honor loved ones.",
ogImage: "visitmexico.com/images/dia-de-muertos.jpg",
ogUrl: "https://www.visitmexico.com/dia-de-muertos", 
ogType: "website"
    },
    'FR': {
      title: 'Découvrez le Día de Muertos au Mexique | Traditions, Autels et Célébrations',
description: 'Explorez le Día de Muertos au Mexique, l’une des traditions les plus emblématiques du pays. Découvrez les autels, les offrandes et les festivités colorées qui honorent nos proches.',
keywords: "Día de Muertos, Mexique, Traditions, Autels, Offrandes, Célébrations, Culture mexicaine, Festivités mexicaines",
author: "Visit México", 
robots: "index, follow", 
ogTitle: "Día de Muertos au Mexique | Traditions, Autels et Célébrations",
ogDescription: "Plongez dans la magie du Día de Muertos au Mexique. Découvrez les traditions, les autels et les événements qui célèbrent la vie et honorent les êtres chers.",
ogImage: "visitmexico.com/images/dia-de-muertos.jpg",
ogUrl: "https://www.visitmexico.com/dia-de-muertos", 
ogType: "website"
    },
    'DE': {
      title: 'Entdecken Sie den Día de Muertos in Mexiko | Traditionen, Altäre und Feiern',
description: 'Erforschen Sie den Día de Muertos in Mexiko, eine der emblematischsten Traditionen des Landes. Entdecken Sie die Altäre, die Opfergaben und die farbenfrohen Feierlichkeiten, die unsere Lieben ehren.',
keywords: "Día de Muertos, Mexiko, Traditionen, Altäre, Opfergaben, Feiern, Mexikanische Kultur, Mexikanische Feste",
author: "Visit México", 
robots: "index, follow", 
ogTitle: "Día de Muertos in Mexiko | Traditionen, Altäre und Feiern",
ogDescription: "Tauchen Sie ein in die Magie des Día de Muertos in Mexiko. Lernen Sie die Traditionen, Altäre und Veranstaltungen kennen, die das Leben feiern und die Erinnerungen an geliebte Menschen ehren.",
ogImage: "visitmexico.com/images/dia-de-muertos.jpg",
ogUrl: "https://www.visitmexico.com/dia-de-muertos", 
ogType: "Website"
    },
    'PT': {
      title: 'Descubra o Día de Muertos no México | Tradições, Altares e Celebrações',
description: 'Explore o Día de Muertos no México, uma das tradições mais emblemáticas do país. Descubra os altares, as oferendas e as festividades cheias de cor que honram nossos entes queridos.',
keywords: "Día de Muertos, México, Tradições, Altares, Oferendas, Celebrações, Cultura Mexicana, Festividades Mexicanas",
author: "Visit México", 
robots: "index, follow", 
ogTitle: "Día de Muertos no México | Tradições, Altares e Celebrações",
ogDescription: "Mergulhe na magia do Día de Muertos no México. Conheça as tradições, altares e eventos que celebram a vida e honram os entes queridos.",
ogImage: "visitmexico.com/images/dia-de-muertos.jpg",
ogUrl: "https://www.visitmexico.com/dia-de-muertos", 
ogType: "website"
    },
    'IT': {
      ttitle: 'Scopri il Día de Muertos in Messico | Tradizioni, Altari e Celebrazioni',
      description: 'Esplora il Día de Muertos in Messico, una delle tradizioni più emblematiche del paese. Scopri gli altari, le offerte e le festività piene di colore che onorano i nostri cari.',
      keywords: "Día de Muertos, Messico, Tradizioni, Altari, Offerte, Celebrazioni, Cultura Messicana, Festività Messicane",
      author: "Visit México", 
      robots: "index, follow", 
      ogTitle: "Día de Muertos in Messico | Tradizioni, Altari e Celebrazioni",
      ogDescription: "Immergiti nella magia del Día de Muertos in Messico. Scopri le tradizioni, gli altari e gli eventi che celebrano la vita e onorano i propri cari.",
      ogImage: "visitmexico.com/images/dia-de-muertos.jpg",
      ogUrl: "https://www.visitmexico.com/dia-de-muertos", 
      ogType: "website"
    },
    'JP': {
      title: 'メキシコのDía de Muertosを発見 | 伝統、祭壇、祝い',
description: 'メキシコのDía de Muertosを探索し、この国の最も象徴的な伝統の一つを体験してください。私たちの愛する人々を称えるための色とりどりの祭壇や供物、祝祭を発見しましょう。',
keywords: "Día de Muertos, メキシコ, 伝統, 祭壇, 供物, 祝い, メキシコ文化, メキシコの祭り",
author: "Visit México", 
robots: "index, follow", 
ogTitle: "メキシコのDía de Muertos | 伝統、祭壇、祝い",
ogDescription: "メキシコのDía de Muertosの魔法に浸ってください。命を祝う伝統や祭壇、愛する人々を称えるイベントを知りましょう。",
ogImage: "visitmexico.com/images/dia-de-muertos.jpg",
ogUrl: "https://www.visitmexico.com/dia-de-muertos", 
ogType: "website"
    },
    'CN': {
      title: '发现墨西哥的Día de Muertos | 传统、祭坛和庆祝活动',
description: '探索墨西哥的Día de Muertos，这是该国最具标志性的传统之一。发现祭坛、供品和色彩缤纷的庆祝活动，以纪念我们的亲人。',
keywords: "Día de Muertos, 墨西哥, 传统, 祭坛, 供品, 庆祝活动, 墨西哥文化, 墨西哥节日",
author: "Visit México", 
robots: "index, follow", 
ogTitle: "墨西哥的Día de Muertos | 传统、祭坛和庆祝活动",
ogDescription: "沉浸在墨西哥的Día de Muertos的魔力中。了解庆祝生命和纪念亲人的传统、祭坛和活动。",
ogImage: "visitmexico.com/images/dia-de-muertos.jpg",
ogUrl: "https://www.visitmexico.com/dia-de-muertos", 
ogType: "website"
    }
  };

  return (
        <div className="main-content muertos-main">
            <MetaTags title={metaInfo[isLanguage].title} description={metaInfo[isLanguage].description} />
            <MuertosVideo data={language} />
            <LazyLoadComponent>
            <MuertosCinta data={language} />
                <MuertosHeader data={language} />
                <MuertosAltar2 data={language}/>
                
                <MuertosInfo data={language} />
               
                <MuertosEventos data={language} />
                <MuertosCinta data={language} />
               {/* <MuertosDestinos sectionTitle={language.sectionTitle} data={language.carousels} /> */}
               <MuertosMapa2 data={language} />
               
                <MuertosVideoSlider data={language} />
                <MuertosFooter  data={language} />
            </LazyLoadComponent>
        </div>
    );
};

export default Muertos;
