import React, { useState, useEffect, useContext } from 'react';
import { Context } from '../../Context';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import acapulcoEs from '../../assets/data/acapulco/es/index.json';
import acapulcoEn from '../../assets/data/acapulco/en/index.json';
import acapulcoFr from '../../assets/data/acapulco/fr/index.json';
import acapulcoDe from '../../assets/data/acapulco/de/index.json';
import acapulcoPt from '../../assets/data/acapulco/pt/index.json';
import acapulcoIt from '../../assets/data/acapulco/it/index.json';
import acapulcoJp from '../../assets/data/acapulco/jp/index.json';
import acapulcoCn from '../../assets/data/acapulco/cn/index.json';

// import MariaIslandsSlider from '../../components/mariaIslandsSlider';


import './styles.css';
import MetaTags from '../../components/metaTags';
import AcapulcoVideo from '../../components/acapulcoVideo';
import AcapulcoDescription from '../../components/acapulcoDescription';
import AcapulcoSlide from '../../components/acapulcoSlide';
import AcapulcoCinta from '../../components/acapulcoCinta';
import AcapulcoReelSection from '../../components/acapulcoReel';
import AcapulcoEjes from '../../components/acapulcoEjes';
import AcapulcoVideoSlider from '../../components/acapulcoVideoSlider';


const Acapulco = () => {
  const { isLanguage } = useContext(Context);
  const [ language, setLanguage ] = useState(acapulcoEs);

  window?.scroll({
    top: 0
  });

  useEffect(() => {
    isLanguage === 'ES' ? setLanguage(acapulcoEs)
    : isLanguage === 'EN' ? setLanguage(acapulcoEn)
    : isLanguage === 'FR' ? setLanguage(acapulcoFr)
    : isLanguage === 'DE' ? setLanguage(acapulcoDe)
    : isLanguage === 'PT' ? setLanguage(acapulcoPt)
    : isLanguage === 'IT' ? setLanguage(acapulcoIt)
    : isLanguage === 'JP' ? setLanguage(acapulcoJp)
    : isLanguage === 'CN' ? setLanguage(acapulcoCn)
    : setLanguage(acapulcoEs)
  }, [isLanguage]);

  const metaInfo = {
    'ES': {
      title: 'Descubre Acapulco - VisitMexico.com',
      description: 'Explora las hermosas playas y la vibrante vida nocturna de Acapulco. Planifica tu viaje con VisitMexico.com y descubre lo mejor de este destino turístico.',
      keywords: "Acapulco, turismo en Acapulco, playas de Acapulco, vida nocturna en Acapulco, viajes a Acapulco, destinos turísticos en México, VisitMexico",
      author: "Visit México", 
      robots: "index, follow", 
      ogTitle: "Descubre Acapulco - VisitMexico.com",
      ogDescription: "Explora las hermosas playas y la vibrante vida nocturna de Acapulco. Planifica tu viaje con VisitMexico.com y descubre lo mejor de este destino turístico.",
      ogImage: "https://www.visitmexico.com/images/acapulco-thumbnail.jpg",
      ogUrl: "https://www.visitmexico.com/acapulco", 
      ogType: "website",
    },
    'EN': {
      title: 'Discover Acapulco - VisitMexico.com',
      description: 'Explore the beautiful beaches and vibrant nightlife of Acapulco. Plan your trip with VisitMexico.com and discover the best of this tourist destination.',
      keywords: "Acapulco, tourism in Acapulco, Acapulco beaches, nightlife in Acapulco, travel to Acapulco, tourist destinations in Mexico, VisitMexico",
      author: "Visit Mexico", 
      robots: "index, follow", 
      ogTitle: "Discover Acapulco - VisitMexico.com",
      ogDescription: "Explore the beautiful beaches and vibrant nightlife of Acapulco. Plan your trip with VisitMexico.com and discover the best of this tourist destination.",
      ogImage: "https://www.visitmexico.com/images/acapulco-thumbnail.jpg",
      ogUrl: "https://www.visitmexico.com/acapulco", 
      ogType: "website",
    },
    'FR': {
      title: 'Découvrez Acapulco - VisitMexico.com',
      description: 'Explorez les belles plages et la vie nocturne vibrante d’Acapulco. Planifiez votre voyage avec VisitMexico.com et découvrez le meilleur de cette destination touristique.',
      keywords: "Acapulco, tourisme à Acapulco, plages d'Acapulco, vie nocturne à Acapulco, voyage à Acapulco, destinations touristiques au Mexique, VisitMexico",
      author: "Visit México", 
      robots: "index, follow", 
      ogTitle: "Découvrez Acapulco - VisitMexico.com",
      ogDescription: "Explorez les belles plages et la vie nocturne vibrante d’Acapulco. Planifiez votre voyage avec VisitMexico.com et découvrez le meilleur de cette destination touristique.",
      ogImage: "https://www.visitmexico.com/images/acapulco-thumbnail.jpg",
      ogUrl: "https://www.visitmexico.com/acapulco", 
      ogType: "website",
    },
    'DE': {
      title: 'Entdecken Sie Acapulco - VisitMexico.com',
      description: 'Erkunden Sie die schönen Strände und das pulsierende Nachtleben von Acapulco. Planen Sie Ihre Reise mit VisitMexico.com und entdecken Sie das Beste dieses Reiseziels.',
      keywords: "Acapulco, Tourismus in Acapulco, Strände von Acapulco, Nachtleben in Acapulco, Reisen nach Acapulco, touristische Ziele in Mexiko, VisitMexico",
      author: "Visit México", 
      robots: "index, follow", 
      ogTitle: "Entdecken Sie Acapulco - VisitMexico.com",
      ogDescription: "Erkunden Sie die schönen Strände und das pulsierende Nachtleben von Acapulco. Planen Sie Ihre Reise mit VisitMexico.com und entdecken Sie das Beste dieses Reiseziels.",
      ogImage: "https://www.visitmexico.com/images/acapulco-thumbnail.jpg",
      ogUrl: "https://www.visitmexico.com/acapulco", 
      ogType: "website",
    },
    'PT': {
      title: 'Descubra Acapulco - VisitMexico.com',
      description: 'Explore as belas praias e a vibrante vida noturna de Acapulco. Planeje sua viagem com VisitMexico.com e descubra o melhor deste destino turístico.',
      keywords: "Acapulco, turismo em Acapulco, praias de Acapulco, vida noturna em Acapulco, viagens para Acapulco, destinos turísticos no México, VisitMexico",
      author: "Visit México", 
      robots: "index, follow", 
      ogTitle: "Descubra Acapulco - VisitMexico.com",
      ogDescription: "Explore as belas praias e a vibrante vida noturna de Acapulco. Planeje sua viagem com VisitMexico.com e descubra o melhor deste destino turístico.",
      ogImage: "https://www.visitmexico.com/images/acapulco-thumbnail.jpg",
      ogUrl: "https://www.visitmexico.com/acapulco", 
      ogType: "website",
    },
    'IT': {
      title: 'Scopri Acapulco - VisitMexico.com',
      description: 'Esplora le bellissime spiagge e la vivace vita notturna di Acapulco. Pianifica il tuo viaggio con VisitMexico.com e scopri il meglio di questa destinazione turistica.',
      keywords: "Acapulco, turismo a Acapulco, spiagge di Acapulco, vita notturna a Acapulco, viaggi ad Acapulco, destinazioni turistiche in Messico, VisitMexico",
      author: "Visit México", 
      robots: "index, follow", 
      ogTitle: "Scopri Acapulco - VisitMexico.com",
      ogDescription: "Esplora le bellissime spiagge e la vivace vita notturna di Acapulco. Pianifica il tuo viaggio con VisitMexico.com e scopri il meglio di questa destinazione turistica.",
      ogImage: "https://www.visitmexico.com/images/acapulco-thumbnail.jpg",
      ogUrl: "https://www.visitmexico.com/acapulco", 
      ogType: "website",
    },
    'JP': {
      title: 'アカプルコを発見 - VisitMexico.com',
      description: '美しいビーチと活気ある夜の生活を楽しむアカプルコを探索しましょう。VisitMexico.comで旅行を計画して、この観光地の最高の体験を見つけてください。',
      keywords: "アカプルコ, アカプルコ観光, アカプルコのビーチ, アカプルコのナイトライフ, アカプルコ旅行, メキシコの観光地, VisitMexico",
      author: "Visit México", 
      robots: "index, follow", 
      ogTitle: "アカプルコを発見 - VisitMexico.com",
      ogDescription: "美しいビーチと活気ある夜の生活を楽しむアカプルコを探索しましょう。VisitMexico.comで旅行を計画して、この観光地の最高の体験を見つけてください。",
      ogImage: "https://www.visitmexico.com/images/acapulco-thumbnail.jpg",
      ogUrl: "https://www.visitmexico.com/acapulco", 
      ogType: "website",
    },
    'CN': {
      title: '发现阿卡普尔科 - VisitMexico.com',
      description: '探索阿卡普尔科的美丽海滩和充满活力的夜生活。通过VisitMexico.com计划您的旅程，发现这个旅游目的地的精彩之处。',
      keywords: "阿卡普尔科, 阿卡普尔科旅游, 阿卡普尔科海滩, 阿卡普尔科夜生活, 阿卡普尔科旅行, 墨西哥旅游目的地, VisitMexico",
      author: "Visit México", 
      robots: "index, follow", 
      ogTitle: "发现阿卡普尔科 - VisitMexico.com",
      ogDescription: "探索阿卡普尔科的美丽海滩和充满活力的夜生活。通过VisitMexico.com计划您的旅程，发现这个旅游目的地的精彩之处。",
      ogImage: "https://www.visitmexico.com/images/acapulco-thumbnail.jpg",
      ogUrl: "https://www.visitmexico.com/acapulco", 
      ogType: "website",
    }
  };

  return (
    <div className="main-content">
      <MetaTags title={metaInfo[isLanguage].title} description={metaInfo[isLanguage].description} />
      {/*<MariaIslandsSlider data={language} />*/}
      <AcapulcoVideo data={language}/>
      <LazyLoadComponent>
        <AcapulcoDescription data={language} />
        <AcapulcoCinta data={language} />
        <AcapulcoSlide data={language.acapulcoSlide} />
        <AcapulcoCinta data={language} />
        <AcapulcoReelSection data={language} />
        <AcapulcoCinta data={language} />
        <AcapulcoEjes data={language} />
        <AcapulcoCinta data={language} />
        <AcapulcoVideoSlider data={language} />
      </LazyLoadComponent>
    </div>
  );
};

export default Acapulco;
