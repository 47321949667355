import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Context } from '../../Context';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import experiencesEs from '../../assets/data/experiences/es/index.json';
import experiencesEn from '../../assets/data/experiences/en/index.json';
import experiencesFr from '../../assets/data/experiences/fr/index.json';
import experiencesDe from '../../assets/data/experiences/de/index.json';
import experiencesPt from '../../assets/data/experiences/pt/index.json';
import experiencesIt from '../../assets/data/experiences/it/index.json';
import experiencesJp from '../../assets/data/experiences/jp/index.json';
import experiencesCn from '../../assets/data/experiences/cn/index.json';
import ExperienceVideo from '../../components/experienceVideo';
import ExperienceImagesSunAndBeach from '../../components/experienceImagesSunAndBeach';
import ExperienceCards from '../../components/experienceCards';
import './styles.css';
import MetaTags from '../../components/metaTags';

const ExperienceSunAndBeach = () => {
  const { isLanguage } = useContext(Context);
  const [ language, setLanguage ] = useState(experiencesEs);
  const location = useLocation();
  const stateId = location?.pathname?.split('/')[2];
  const getStateInfo = language?.experiences?.filter(experience => experience.id === stateId);
  const data = getStateInfo?.[0];

  window?.scroll({
    top: 0
  });

  useEffect(() => {
    isLanguage === 'ES' ? setLanguage(experiencesEs)
    : isLanguage === 'EN' ? setLanguage(experiencesEn)
    : isLanguage === 'FR' ? setLanguage(experiencesFr)
    : isLanguage === 'DE' ? setLanguage(experiencesDe)
    : isLanguage === 'PT' ? setLanguage(experiencesPt)
    : isLanguage === 'IT' ? setLanguage(experiencesIt)
    : isLanguage === 'JP' ? setLanguage(experiencesJp)
    : isLanguage === 'CN' ? setLanguage(experiencesCn)
    : setLanguage(experiencesEs)
  }, [isLanguage]);

  const metaInfo = {
    'ES': {
      title: 'Experiencias de Sol y Playa en México | Visit México',
      description: 'Descubre las mejores experiencias de sol y playa en México: desde playas vírgenes hasta resorts de lujo, en el sitio oficial de Visit México. Disfruta del sol y el mar en México.'
    },
    'EN': {
      title: 'Sun and Beach Experiences in Mexico | Visit Mexico',
      description: 'Discover the best sun and beach experiences in Mexico: from pristine beaches to luxury resorts, on the official Visit Mexico website. Enjoy the sun and sea in Mexico.'
    },
    'FR': {
      title: 'Expériences de Soleil et Plage au Mexique | Visit Mexico',
      description: 'Découvrez les meilleures expériences de soleil et plage au Mexique : des plages vierges aux complexes de luxe, sur le site officiel de Visit Mexico. Profitez du soleil et de la mer au Mexique.'
    },
    'DE': {
      title: 'Sonnen- und Strand-Erlebnisse in Mexiko | Visit Mexico',
      description: 'Entdecken Sie die besten Sonnen- und Strand-Erlebnisse in Mexiko: von unberührten Stränden bis zu Luxusresorts, auf der offiziellen Visit Mexico Website. Genießen Sie Sonne und Meer in Mexiko.'
    },
    'PT': {
      title: 'Experiências de Sol e Praia no México | Visit Mexico',
      description: 'Descubra as melhores experiências de sol e praia no México: de praias paradisíacas a resorts de luxo, no site oficial do Visit Mexico. Aproveite o sol e o mar no México.'
    },
    'IT': {
      title: 'Esperienze di Sole e Spiaggia in Messico | Visit Mexico',
      description: 'Scopri le migliori esperienze di sole e spiaggia in Messico: dalle spiagge incontaminate ai resort di lusso, sul sito ufficiale di Visit Mexico. Goditi il sole e il mare in Messico.'
    },
    'JP': {
      title: 'メキシコの太陽とビーチ体験 | Visit Mexico',
      description: 'Visit Mexico公式ウェブサイトで、メキシコの最高の太陽とビーチ体験を発見しよう：未開のビーチから高級リゾートまで。メキシコの太陽と海を満喫しよう。'
    },
    'CN': {
      title: '墨西哥的阳光与海滩体验 | Visit Mexico',
      description: '在Visit Mexico官方网站上发现墨西哥最佳的阳光与海滩体验：从原始海滩到豪华度假村。在墨西哥享受阳光和海洋。'
    }
  };

  return (
    <div className="main-content">
      <MetaTags title={metaInfo[isLanguage].title} description={metaInfo[isLanguage].description} />
      <ExperienceVideo data={data} />
      <LazyLoadComponent>
        <ExperienceImagesSunAndBeach data={data} />

      </LazyLoadComponent>
    </div>
  );
};

export default ExperienceSunAndBeach;
