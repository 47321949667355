import React, { useContext } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNavigate } from 'react-router-dom';
import './styles.css';

// Función para normalizar el nombre del estado
const normalizeStringForURL = (str) => {
    return encodeURIComponent(str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().replace(/\s+/g, '-'));
};

const DescargasCarousel = ({ data }) => {
    const navigate = useNavigate();

    const handleItemClick = (estado) => {
        const estadoSlug = normalizeStringForURL(estado); // Normaliza el estado
        const estadoData = data.find(item => item.nombreEstado === estado); // Encuentra el estado en los datos

        if (estadoData) {
            navigate(`/descargas/${estadoSlug}`);
        }
    };

    const numberOfRows = Math.ceil(data.length / 4);

    const rows = Array.from({ length: numberOfRows }, (_, index) => {
        const startIndex = index * 4;
        const endIndex = startIndex + 4;
        return data.slice(startIndex, endIndex);
    });

    return (
        <div className="carousel-container">
            {rows.map((row, rowIndex) => (
                <div key={rowIndex} className="carousel-row">
                    {row.map((carousel, index) => (
                        <div key={index} className="carousel-item">
                            <h2>{carousel.title}</h2>
                            <div className="carousel">
                                <Carousel
                                    showThumbs={false}
                                    showStatus={false}
                                    renderArrowPrev={(onClickHandler, hasPrev, label) =>
                                        hasPrev && (
                                            <button type="button" onClick={onClickHandler} title={label} className="arrow-prev">
                                                &#8249;
                                            </button>
                                        )
                                    }
                                    renderArrowNext={(onClickHandler, hasNext, label) =>
                                        hasNext && (
                                            <button type="button" onClick={onClickHandler} title={label} className="arrow-next">
                                                &#8250;
                                            </button>
                                        )
                                    }
                                >
                                    {carousel.images.map((image, i) => (
                                        <div
                                            key={i}
                                            className="carousel-image-wrapper"
                                            onClick={() => handleItemClick(carousel.nombreEstado)} // Manejador de clics
                                        >
                                            <div className="carousel-image-overlay"></div>
                                            <div className="carousel-image-content">
                                                {i === 0 && (
                                                    <div className="first-slide-text-container">
                                                        <p className="first-slide-text">{carousel.nombreEstado}</p>
                                                    </div>
                                                )}
                                                <LazyLoadImage
                                                    src={require(`../../assets/img/${image}`)}
                                                    alt={`Image ${i}`}
                                                    className="lazy-loaded barrios-lazy-image-carousel"
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </Carousel>
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default DescargasCarousel;