import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Context } from '../../Context';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

import descargasEs from '../../assets/data/descargas/es/index.json';
import descargasDe from '../../assets/data/descargas/de/index.json';
import descargasFr from '../../assets/data/descargas/fr/index.json';
import descargasEn from '../../assets/data/descargas/en/index.json';
import descargasIt from '../../assets/data/descargas/it/index.json';
import descargasPt from '../../assets/data/descargas/pt/index.json';
import descargasJp from '../../assets/data/descargas/jp/index.json';
import descargasCn from '../../assets/data/descargas/cn/index.json';
import './styles.css';
import MetaTags from '../../components/metaTags';
import DescargasHeroSlider from '../../components/descargasHeroSlider';
import DescargasHeader from '../../components/descargasHeader';
import DescargasCarousel from '../../components/descargasCarousel';
import DescargasVideoSlider from '../../components/descargasVideoSlider';

const Descargas = () => {
    const { isLanguage } = useContext(Context);
    const [language, setLanguage] = useState(descargasEs);

    window?.scroll({
        top: 0
    });

    useEffect(() => {
        isLanguage === 'ES' ? setLanguage(descargasEs)
            : isLanguage === 'DE' ? setLanguage(descargasDe)
            : isLanguage === 'FR' ? setLanguage(descargasFr)
            : isLanguage === 'EN' ? setLanguage(descargasEn)
            : isLanguage === 'IT' ? setLanguage(descargasIt)
            : isLanguage === 'PT' ? setLanguage(descargasPt)
            : isLanguage === 'JP' ? setLanguage(descargasJp)
            : isLanguage === 'CN' ? setLanguage(descargasCn)
            : setLanguage(descargasEs)
    }, [isLanguage]);

    const metaInfo = {
        'ES': {
            title: 'Descargas de Imágenes y Videos de México - VisitMexico',
            description: 'Descubre y descarga imágenes y videos de los estados de la República Mexicana. Explora la belleza y la diversidad de México a través de nuestra galería.'
        },
        'EN': {
            title: 'Downloads of Images and Videos from Mexico - VisitMexico',
            description: 'Discover and download images and videos from the states of Mexico. Explore the beauty and diversity of Mexico through our gallery.'
        },
        'FR': {
            title: 'Téléchargements d\'images et de vidéos du Mexique - VisitMexico',
            description: 'Découvrez et téléchargez des images et des vidéos des états du Mexique. Explorez la beauté et la diversité du Mexique à travers notre galerie.'
        },
        'DE': {
            title: 'Downloads von Bildern und Videos aus Mexiko - VisitMexico',
            description: 'Entdecken und laden Sie Bilder und Videos der Bundesstaaten Mexikos herunter. Erforschen Sie die Schönheit und Vielfalt Mexikos durch unsere Galerie.'
        },
        'PT': {
            title: 'Downloads de Imagens e Vídeos do México - VisitMexico',
            description: 'Descubra e baixe imagens e vídeos dos estados do México. Explore a beleza e a diversidade do México através da nossa galeria.'
        },
        'IT': {
            title: 'Download di Immagini e Video del Messico - VisitMexico',
            description: 'Scopri e scarica immagini e video degli stati del Messico. Esplora la bellezza e la diversità del Messico attraverso la nostra galleria.'
        },
        'JP': {
            title: 'メキシコの画像とビデオのダウンロード - VisitMexico',
            description: 'メキシコの州からの画像とビデオを発見し、ダウンロードしましょう。ギャラリーを通してメキシコの美しさと多様性を探求しよう。'
        },
        'CN': {
            title: '墨西哥图片和视频下载 - VisitMexico',
            description: '发现并下载墨西哥各州的图片和视频。通过我们的画廊探索墨西哥的美丽和多样性。'
        }
    };

    return (
        <div className="main-content">
            <MetaTags title={metaInfo[isLanguage].title} description={metaInfo[isLanguage].description} />
            <DescargasHeroSlider data={language} />
            <LazyLoadComponent>
                <DescargasHeader data={language} />
                <DescargasCarousel data={language.carousels} />
                <DescargasVideoSlider data={language} />
            </LazyLoadComponent>
        </div>
    );
};

export default Descargas;
