import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Context } from '../../Context';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import experiencesEs from '../../assets/data/experiences/es/index.json';
import experiencesEn from '../../assets/data/experiences/en/index.json';
import experiencesFr from '../../assets/data/experiences/fr/index.json';
import experiencesDe from '../../assets/data/experiences/de/index.json';
import experiencesPt from '../../assets/data/experiences/pt/index.json';
import experiencesIt from '../../assets/data/experiences/it/index.json';
import experiencesJp from '../../assets/data/experiences/jp/index.json';
import experiencesCn from '../../assets/data/experiences/cn/index.json';
import ExperienceVideo from '../../components/experienceVideo';
import ExperienceAventureHero from '../../components/experienceAventureHero';
import ExperienceAventureGallery from '../../components/experienceAventureGallery';
import ExperienceAventureTops from '../../components/experienceAventureTops';
import ExperienceCards from '../../components/experienceCards';
import './styles.css';
import MetaTags from '../../components/metaTags';

const ExperienceAventure = () => {
  const { isLanguage } = useContext(Context);
  const [ language, setLanguage ] = useState(experiencesEs);
  const location = useLocation();
  const stateId = location?.pathname?.split('/')[2];
  const getStateInfo = language?.experiences?.filter(experience => experience.id === stateId);
  const data = getStateInfo?.[0];

  window?.scroll({
    top: 0
  });

  useEffect(() => {
    isLanguage === 'ES' ? setLanguage(experiencesEs)
    : isLanguage === 'EN' ? setLanguage(experiencesEn)
    : isLanguage === 'FR' ? setLanguage(experiencesFr)
    : isLanguage === 'DE' ? setLanguage(experiencesDe)
    : isLanguage === 'PT' ? setLanguage(experiencesPt)
    : isLanguage === 'IT' ? setLanguage(experiencesIt)
    : isLanguage === 'JP' ? setLanguage(experiencesJp)
    : isLanguage === 'CN' ? setLanguage(experiencesCn)
    : setLanguage(experiencesEs)
  }, [isLanguage]);

  const metaInfo = {
    'ES': {
      title: 'Experiencias de Aventura en México | Visit México',
      description: 'Descubre las mejores experiencias de aventura en México: desde senderismo en montañas hasta buceo en arrecifes, en el sitio oficial de Visit México. Vive la adrenalina en México.'
    },
    'EN': {
      title: 'Adventure Experiences in Mexico | Visit Mexico',
      description: 'Discover the best adventure experiences in Mexico: from mountain hiking to reef diving, on the official Visit Mexico website. Experience the adrenaline in Mexico.'
    },
    'FR': {
      title: 'Expériences Aventure au Mexique | Visit Mexico',
      description: 'Découvrez les meilleures expériences d\'aventure au Mexique : de la randonnée en montagne à la plongée en récif, sur le site officiel de Visit Mexico. Vivez l\'adrénaline au Mexique.'
    },
    'DE': {
      title: 'Abenteuererlebnisse in Mexiko | Visit Mexico',
      description: 'Entdecken Sie die besten Abenteuererlebnisse in Mexiko: vom Wandern in den Bergen bis zum Tauchen im Riff, auf der offiziellen Visit Mexico Website. Erleben Sie die Adrenalin in Mexiko.'
    },
    'PT': {
      title: 'Experiências de Aventura no México | Visit Mexico',
      description: 'Descubra as melhores experiências de aventura no México: desde caminhadas em montanhas até mergulho em recifes, no site oficial do Visit Mexico. Experimente a adrenalina no México.'
    },
    'IT': {
      title: 'Esperienze di Avventura in Messico | Visit Mexico',
      description: 'Scopri le migliori esperienze di avventura in Messico: dal trekking in montagna all\'immersione nei reef, sul sito ufficiale di Visit Mexico. Vivi l\'adrenalina in Messico.'
    },
    'JP': {
      title: 'メキシコのアドベンチャー体験 | Visit Mexico',
      description: 'Visit Mexico公式ウェブサイトで、メキシコでの最高のアドベンチャー体験を発見しよう：山岳ハイキングからリーフダイビングまで。メキシコでアドレナリンを体験しよう。'
    },
    'CN': {
      title: '墨西哥的冒险体验 | Visit Mexico',
      description: '在Visit Mexico官方网站上发现墨西哥最佳的冒险体验：从山区徒步到礁岩潜水。在墨西哥体验肾上腺素的冲击。'
    }
  };

  return (
    <div className="main-content">
      <MetaTags title={metaInfo[isLanguage].title} description={metaInfo[isLanguage].description} />
      <ExperienceVideo data={data} />
      <LazyLoadComponent>
        <ExperienceAventureHero data={data} />
        <ExperienceAventureGallery data={data} />
        {/*<ExperienceAventureTops data={data} />*/}
        {/*<ExperienceCards data={data} />*/}
      </LazyLoadComponent>
    </div>
  );
};

export default ExperienceAventure;
