import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import './styles.css';



const GeoparquesHeader = ({ data }) => {
    return (
        <>
      <div className="geoparques-header-ctn">
        <h1 className="geoparques-header-title">{data?.title}</h1>
        
      </div>

      <div className="container-geoparques-info">
            <div className="geoparques-left-column">
                <LazyLoadImage
                    src={require(`../../assets/img/${data.imgLogo}`)}
                    alt="Imagen"
                    className="lazy-loaded-image lazy-loaded-image-header"
                />
            </div>
            <div className="geoparques-right-column">

                <ul>
                    <li className="geoparques-paragraph"> {data.barriosParrafo1}</li>
                    <br />
                    <li className="geoparques-paragraph">  {data.barriosParrafo2}</li>
                </ul>
  
                
            </div>
        </div>      

      </>
    );
  };
  
  export default GeoparquesHeader;