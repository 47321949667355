import React from 'react';
import './styles.css'; // Asegúrate de importar los estilos

const GeoParquesMexico = ({ data }) => {
  return (
    <div className="geoparqueMexico-container">
      <h2 className="geoparqueMexico-header-title">{data?.geoMexicoTitle}</h2>
      <p className="geoparqueMexico-paragraph">
        {data?.geoMexicoIntro}
      </p>
      <div className="geoparqueMexico-columns">
        {/* Columna izquierda */}
        <div className="geoparqueMexico-left-column">
          <h3 className="geoparqueMexico-subtitle">Actividades Turísticas</h3>
          <ul className="geoparqueMexico-list">
            {data?.touristActivities?.map((activity, index) => (
              <li key={index} className="geoparqueMexico-list-item">{activity}</li>
            ))}
          </ul>
        </div>
        
        {/* Columna derecha */}
        <div className="geoparqueMexico-right-column">
          <p className="geoparqueMexico-paragraph">
            {data?.geoMexicoGeoparksIntro}
          </p>
          <ul className="geoparqueMexico-geopark-list">
            {data?.geoparks?.map((geopark, index) => (
              <li key={index} className="geoparqueMexico-geopark-item">{geopark}</li>
            ))}
          </ul>
        </div>
      </div>
      <p className="geoparqueMexico-paragraph">
        {data?.geoMexicoProjectIntro}
      </p>
    </div>
  );
};

export default GeoParquesMexico;