import React, { useState, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import './styles.css';

const CiudadesPatrimonioInfo = ({ data }) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const element = document.querySelector('.container-ciudades-info');
            if (!element) return; // Salir si el elemento no está presente
            const topElement = element.getBoundingClientRect().top;
            const height = window.innerHeight;
            if (topElement < height - height / 4) {
                setIsVisible(true);
                window.removeEventListener('scroll', handleScroll);
            }
        };

        // Verificar si el elemento es visible cuando se carga la página
        handleScroll();

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

   

    return (
        <>
        <div className={`container-ciudades-info ${isVisible ? 'reveal-scroll visible' : ''}`}>
            <div>
                <h1 className="barrios-header-title">{data?.subtitle}</h1>
            </div>
            <div className="ciudades-left-column">
                <LazyLoadImage
                    src={require(`../../assets/img/${data.imgTurismo}`)}
                    alt="Imagen"
                    className="lazy-loaded-image" style={ { maxWidth: '400px' }}
                />
            </div>
            <div className="magic-towns-know-ctn-button-link" style={{ maxWidth: '400px', margin:'0 auto' }}>
                <a 
                href={data?.actions?.dir} 
                target="_blank" 
                rel="noopener noreferrer"
                className="magic-towns-know-button-link"
                >
                {data?.actions?.button}
                </a>
            </div>
        </div>
        </>
    );
};

export default CiudadesPatrimonioInfo;
