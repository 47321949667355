import React from 'react';
import './styles.css';

const GeoparqueSingle = ({ data }) => {
    return (
      <div className="geoparqueSingle-container">
        <h2 className="geoparqueSingle-header-title">{data?.Hidalgotitulo}</h2>
        <p className="geoparqueSingle-paragraph">{data?.Hidalgoparrafo1}</p>
        <p className="geoparqueSingle-paragraph">{data?.Hidalgoparrafo2}</p>
        <p className="geoparqueSingle-paragraph">{data?.Hidalgoparrafo3}</p>
        <p className="geoparqueSingle-paragraph">{data?.Hidalgoparrafo4}</p>
        <ul className="geoparqueSingle-list">
          {data?.HidalgopueblosMagicos?.map((pueblo, index) => (
            <li key={index} className="geoparqueSingle-list-item">{pueblo}</li>
          ))}
        </ul>
        <p className="geoparqueSingle-paragraph">{data?.Hidalgoparrafo5}</p>
        <ul className="geoparqueSingle-list">
          {data?.HidalgopueblosConSabor?.map((pueblo, index) => (
            <li key={index} className="geoparqueSingle-list-item">{pueblo}</li>
          ))}
        </ul>
        <p className="geoparqueSingle-paragraph">{data?.Hidalgoparrafo6}</p>
      </div>
    );
  };
  
  export default GeoparqueSingle;