import React, { useState } from 'react';
import './styles.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css'; // Opcional: para añadir efectos
import Fuse from 'fuse.js';

const DescargasHeroSlider = ({ data }) => {
  // Configuración de Fuse.js para búsqueda en nombreEstado
  const fuse = new Fuse(data.carousels, {
    keys: ['nombreEstado'],
    threshold: 0.4, // Ajusta la sensibilidad de la búsqueda
  });

  // Estado para la búsqueda y los resultados
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);

  // Función de manejo de búsqueda
  const handleSearch = (event) => {
    const { value } = event.target;
    setQuery(value);

    if (value) {
      const searchResults = fuse.search(value).map(({ item }) => item);
      setResults(searchResults);
    } else {
      setResults([]); // Limpiar los resultados si la búsqueda está vacía
    }
  };

  return (
    <div className="hero-slider-container-descargas">
      {/* Cargar la imagen de fondo con LazyLoadImage */}
      <LazyLoadImage
        src={require(`../../assets/img/${data.backgroundImage}`)} // Ruta de la imagen
        alt={data.title}
        effect="blur" // Puedes cambiar a 'opacity', 'black-and-white', etc.
        className="background-image" // Añadir clase para CSS
        wrapperClassName="background-image-wrapper"
      />
      
      <div className="overlay">
        <div className="content">
          <h1>{data.title}</h1>
          <p>{data.subtitle}</p>
          {/** 
          <input
            type="text"
            placeholder={data.placeholder}
            value={query}
            onChange={handleSearch}
          /> */}
          {query && ( // Mostrar la lista solo si hay una consulta
            <div className="results-container">
              <ul className="results-list">
                {results.length > 0 ? (
                  results.map((item, index) => (
                    <li key={index}>{item.nombreEstado}</li>
                  ))
                ) : (
                  <li>No se encontraron resultados</li> // Mensaje cuando no hay resultados
                )}
              </ul>
            </div>
          )}
        </div>
      </div>
      
    </div>
  );
};

export default DescargasHeroSlider;