import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Context } from '../../Context';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

import ciudadesPatrimonioEs from '../../assets/data/ciudadesPatrimonio/es/index.json';
import ciudadesPatrimonioDe from '../../assets/data/ciudadesPatrimonio/de/index.json';
import ciudadesPatrimonioFr from '../../assets/data/ciudadesPatrimonio/fr/index.json';
import ciudadesPatrimonioEn from '../../assets/data/ciudadesPatrimonio/en/index.json';
import ciudadesPatrimonioIt from '../../assets/data/ciudadesPatrimonio/it/index.json';
import ciudadesPatrimonioPt from '../../assets/data/ciudadesPatrimonio/pt/index.json';
import ciudadesPatrimonioJp from '../../assets/data/ciudadesPatrimonio/jp/index.json';
import ciudadesPatrimonioCn from '../../assets/data/ciudadesPatrimonio/cn/index.json';
import './styles.css';
import CiuadesPatrimonioHeroSlider from '../../components/ciudadesPatrimonioHeroSlider';
import CiudadesPatrimonioHeader from '../../components/ciudadesPatrimonioHeader';
import CiudadesPatrimonioGaleria from '../../components/ciudadesPatrimonioGaleria';
import CiudadesPatrimonioInfo from '../../components/ciudadesPatrimonioInfo';
import CintaRoja from '../../components/cintaRoja';
import CiudadesPatrimonioCarousel from '../../components/ciudadesPatrimonioCarrusel';
import MetaTags from '../../components/metaTags';

const CiudadesPatrimonio = () => {
    const { isLanguage } = useContext(Context);
    const [language, setLanguage] = useState(ciudadesPatrimonioEs);

    window?.scroll({
        top: 0
    });

    useEffect(() => {
        isLanguage === 'ES' ? setLanguage(ciudadesPatrimonioEs)
            : isLanguage === 'DE' ? setLanguage(ciudadesPatrimonioDe)
            : isLanguage === 'FR' ? setLanguage(ciudadesPatrimonioFr)
            : isLanguage === 'EN' ? setLanguage(ciudadesPatrimonioEn)
            : isLanguage === 'IT' ? setLanguage(ciudadesPatrimonioIt)
            : isLanguage === 'PT' ? setLanguage(ciudadesPatrimonioPt)
            : isLanguage === 'JP' ? setLanguage(ciudadesPatrimonioJp)
            : isLanguage === 'CN' ? setLanguage(ciudadesPatrimonioCn)
            : setLanguage(ciudadesPatrimonioEs)
    }, [isLanguage]);

  const metaInfo = {
    'ES': {
      title: 'Ciudades Patrimonio de México - VisitMexico',
      description: 'Descubre las Ciudades Patrimonio de México. Explora la riqueza cultural, histórica y arquitectónica de estas ciudades únicas.'
    },
    'EN': {
      title: 'Heritage Cities of Mexico - VisitMexico',
      description: 'Discover the Heritage Cities of Mexico. Explore the cultural, historical, and architectural richness of these unique cities.'
    },
    'FR': {
      title: 'Villes Patrimoine du Mexique - VisitMexico',
      description: 'Découvrez les Villes Patrimoine du Mexique. Explorez la richesse culturelle, historique et architecturale de ces villes uniques.'
    },
    'DE': {
      title: 'Welterbestädte Mexikos - VisitMexico',
      description: 'Entdecken Sie die Welterbestädte Mexikos. Erkunden Sie den kulturellen, historischen und architektonischen Reichtum dieser einzigartigen Städte.'
    },
    'PT': {
      title: 'Cidades Patrimônio do México - VisitMexico',
      description: 'Descubra as Cidades Patrimônio do México. Explore a riqueza cultural, histórica e arquitetônica dessas cidades únicas.'
    },
    'IT': {
      title: 'Città Patrimonio del Messico - VisitMexico',
      description: 'Scopri le Città Patrimonio del Messico. Esplora la ricchezza culturale, storica e architettonica di queste città uniche.'
    },
    'JP': {
      title: 'メキシコの遺産都市 - VisitMexico',
      description: 'メキシコの遺産都市を発見しよう。これらのユニークな都市の文化的、歴史的、建築的な豊かさを探索しよう。'
    },
    'CN': {
      title: '墨西哥的遗产城市 - VisitMexico',
      description: '探索墨西哥的遗产城市。探索这些独特城市的文化、历史和建筑的丰富性。'
    }
  };

  return (
        <div className="main-content">
            <MetaTags title={metaInfo[isLanguage].title} description={metaInfo[isLanguage].description} />
            <CiuadesPatrimonioHeroSlider data={language.heroSlide} />
            <LazyLoadComponent>
                <CiudadesPatrimonioHeader data={language} />
                <CiudadesPatrimonioGaleria data={language.galeria} />
                <CiudadesPatrimonioInfo data={language} />
                <CintaRoja data={language} />
                <CiudadesPatrimonioCarousel data={language.carousels} />
            </LazyLoadComponent>
        </div>
    );
};

export default CiudadesPatrimonio;
