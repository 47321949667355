import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./styles.css";

const AcapulcoDescription = ({ data }) => {
  const getDescription = () =>
    data?.description?.map((item, index) => (
      <p className="acapulco-description-content-text" key={index}>
        {item?.text}
      </p>
    ));

  return (
    <div className="acapulco-description-ctn">
      <div className="acapulco-description-content-ctn">
        <div className="acapulco-description-content-ctn-text text-center">
          <h1 className="acapulco-description-content-title">{data?.title}</h1>
          {getDescription()}
        </div>
      </div>
    </div>
  );
};

export default AcapulcoDescription;
