import React from 'react';
import './styles.css';

const AcapulcoEjes = ({ data }) => {
  return (
    <>
      <div className="acapulco_reel_containerClasificacion">
        <div className="acapulco_reel_row">
          <div className="acapulco_reel_column">
            <h2 className="acapulco_reel_titleClasificacion">{data.titleClasificacion}</h2>
            <p>{data.descripcionClasificacion}</p>
          </div>
        </div>
        <div className="acapulco_reel_row">
          {data.clasificacionColumnas.map((columna, index) => (
            <div className="acapulco_reel_column" key={index}>
              <div className="acapulco_reel_icon-container">
                <img className="acapulco_reel_icon-img" src={require(`../../assets/img/${columna.imagen}`)} alt={`Icono ${index + 1}`} />
              </div>
              <p className='text-center'>{columna.descripcion}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="acapulco_reel_cinta-rosa"></div>
    </>
  );
};

export default AcapulcoEjes;