import React, { useState, useEffect, useContext } from "react";
import { Link } from 'react-router-dom';
import { Context } from '../../Context';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment/min/moment-with-locales';
import "./styles.css";
import Modal from 'react-modal';
import {FaTimes} from 'react-icons/fa';

const localizer = momentLocalizer(moment);
moment.locale('es');

const CalendarioTime = ({ data }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const { isLanguage } = useContext(Context);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [detailModal, setDetailGuide] = useState(false);

  const showModal = (item) => {
    setIsOpen(true);
    setDetailGuide(item);
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const locales = {
    ES: 'es',
    EN: 'en-gb',
    FR: 'fr',
    DE: 'de',
    PT: 'pt',
    IT: 'it',
    JP: 'ja',
    CN: 'zh-cn',
  };

  // Transformar los eventos en el formato requerido por BigCalendar
  const events = data.events.map(event => event && ({
    title: event.title,
    start: event.start,
    end: event.end,
    allDay: true,
    desc: event.text,
    urlImg: event.urlImg,
    vinculo: event.vinculo
  }));

  const [styleDetailDate, setStyleDetailDate] = useState('firtscontainer2');
  const [eventImg, setEventImg] = useState('calendario/ofrendas.jpg');
  const [eventLink, setEventLink] = useState('https://www.facebook.com/VivaAguascalientes?mibextid=ZbWKwL');
  const [titleDate, setTitleDate] = useState('Vendimias aguascalientes');

  useEffect(() => {
    const locale = locales[isLanguage];
    moment.locale(locale);
  }, [isLanguage]);

  useEffect(() => {
    setIsOpen(false);
    setSelectedEvent(null);
  }, []);

  const handleNext = () => {
    setCurrentDate(moment(currentDate).add(1, 'month').toDate());
  };

  const handleBack = () => {
    setCurrentDate(moment(currentDate).subtract(1, 'month').toDate());
  };

  const   handleDateClick = (event) => {
    setSelectedEvent(event);
    setIsOpen(true);
    setStyleDetailDate('firtscontainer2');
    setTitleDate(event.title);
    setEventImg(event.urlImg);
    setEventLink(event.vinculo);
  };

  const closeAdvertisement = () => {
    setStyleDetailDate('firtscontainer');
  };

  return (
    <section>
      <LazyLoadImage
        src={require(`../../assets/img/header/Fondoamarrillo.webp`)}
        alt=""
        className="calendario2-franja"
      />
      <h2 className="calendar-known-title">{data?.title || "Título no disponible"} </h2>

      <div className="contenedor-flexbox">
        <div className="secondcontainer">
          <div className="toolbar">
            <button onClick={handleBack}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="arrow-left">
                <polyline points="15 18 9 12 15 6"/>
              </svg>
            </button>
            <span className={'text-capitalize'}>{moment(currentDate).format('MMMM YYYY')}</span>
            <button onClick={handleNext}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="arrow-right">
                <polyline points="9 18 15 12 9 6"/>
              </svg>
            </button>
          </div>

          <BigCalendar
            className="h-100 w-100"
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            views={['month']}
            style={{ height: 1200 }}
            date={currentDate}
            truncateEvents={false}
            toolbar={false}
            onNavigate={(newDate) => setCurrentDate(newDate)}
            onSelectEvent={handleDateClick} // Evento seleccionado
          />
          <br/>
        </div>
      </div>
      <br/>

      {modalIsOpen && (
        <div className="lgbt-modal-overlay" onClick={closeModal}>
          <div className="lgbt-modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="lgbt-modal-details">
              <h2 className="lgbt-modal-title">{selectedEvent.title}</h2>
              <span>{moment(selectedEvent.start).format("DD/MM/YYYY")} - {moment(selectedEvent.end).format("DD/MM/YYYY")}</span>
              <p className="lgbt-modal-description">{selectedEvent?.desc || "-------"}</p>
            </div>
            <button className="lgbt-modal-close" onClick={closeModal}>
              <FaTimes />
            </button>
          </div>
        </div>
      )}
    </section>
  );
};

export default CalendarioTime;
