import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Context } from '../../Context';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

import pueblosMagicosEs from '../../assets/data/pueblosMagicos/es/index.json';
import pueblosMagicosEn from '../../assets/data/pueblosMagicos/en/index.json';
import pueblosMagicosFr from '../../assets/data/pueblosMagicos/fr/index.json';
import pueblosMagicosDe from '../../assets/data/pueblosMagicos/de/index.json';
import pueblosMagicosPt from '../../assets/data/pueblosMagicos/pt/index.json';
import pueblosMagicosIt from '../../assets/data/pueblosMagicos/it/index.json';
import pueblosMagicosJp from '../../assets/data/pueblosMagicos/jp/index.json';
import pueblosMagicosCn from '../../assets/data/pueblosMagicos/cn/index.json';
import './styles.css';
import MapaPueblosMagicos from '../../components/mapaPueblosMagicos';
import MapaDescarga from '../../components/mapaPueblosMagicosDescarga';
import MetaTags from '../../components/metaTags';

const MapaPueblos = () => {
    const { isLanguage } = useContext(Context);
    const [language, setLanguage] = useState(pueblosMagicosEs);

    window?.scroll({
        top: 0
    });

    useEffect(() => {
        isLanguage === 'ES' ? setLanguage(pueblosMagicosEs)
        : isLanguage === 'EN' ? setLanguage(pueblosMagicosEn)
        : isLanguage === 'FR' ? setLanguage(pueblosMagicosFr)
        : isLanguage === 'DE' ? setLanguage(pueblosMagicosDe)
        : isLanguage === 'PT' ? setLanguage(pueblosMagicosPt)
        : isLanguage === 'IT' ? setLanguage(pueblosMagicosIt)
        : isLanguage === 'JP' ? setLanguage(pueblosMagicosJp)
        : isLanguage === 'CN' ? setLanguage(pueblosMagicosCn)
            : setLanguage(pueblosMagicosEs)
    }, [isLanguage]);

    const metaInfo = {
        'ES': {
            title: 'Mapa de Pueblos Mágicos de México - VisitMexico',
            description: 'Consulta el mapa interactivo de los Pueblos Mágicos de México. Descubre sus ubicaciones y planifica tu viaje.'
        },
        'EN': {
            title: 'Map of Magical Towns of Mexico - VisitMexico',
            description: 'Check out the interactive map of Mexico\'s Magical Towns. Discover their locations and plan your trip.'
        },
        'FR': {
            title: 'Carte des Villes Magiques du Mexique - VisitMexico',
            description: 'Consultez la carte interactive des Villes Magiques du Mexique. Découvrez leurs emplacements et planifiez votre voyage.'
        },
        'DE': {
            title: 'Karte der Magischen Städte Mexikos - VisitMexico',
            description: 'Sehen Sie sich die interaktive Karte der magischen Städte Mexikos an. Entdecken Sie ihre Standorte und planen Sie Ihre Reise.'
        },
        'PT': {
            title: 'Mapa das Cidades Mágicas do México - VisitMexico',
            description: 'Confira o mapa interativo das Cidades Mágicas do México. Descubra suas localizações e planeje sua viagem.'
        },
        'IT': {
            title: 'Mappa delle Città Magiche del Messico - VisitMexico',
            description: 'Consulta la mappa interattiva delle Città Magiche del Messico. Scopri le loro posizioni e pianifica il tuo viaggio.'
        },
        'JP': {
            title: 'メキシコのマジカルタウンマップ - VisitMexico',
            description: 'メキシコのマジカルタウンのインタラクティブマップをチェックしよう。 それらの場所を発見し、旅行を計画しよう。'
        },
        'CN': {
            title: '墨西哥魔法城镇地图 - VisitMexico',
            description: '查看墨西哥魔法城镇的互动地图。发现它们的位置并计划你的旅行。'
        }
    };

    return (
        <div className="main-content">
            <MetaTags title={metaInfo[isLanguage].title} description={metaInfo[isLanguage].description} />
           <MapaPueblosMagicos data={language.marcadores} dataLabels={language.staticItems}/> {/* Pasa solo los marcadores */}
        </div>
    );
};

export default MapaPueblos;
