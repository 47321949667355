import React, { useState, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import './styles.css';

const MuertosInfo = ({ data }) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const element = document.querySelector('.muertos-container-ciudades-info');
            if (!element) return; // Salir si el elemento no está presente
            const topElement = element.getBoundingClientRect().top;
            const height = window.innerHeight;
            if (topElement < height - height / 4) {
                setIsVisible(true);
                window.removeEventListener('scroll', handleScroll);
            }
        };

        // Verificar si el elemento es visible cuando se carga la página
        handleScroll();

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

   

    return (
        <>
        <div className={`muertos-muertos-container-ciudades-info ${isVisible ? 'reveal-scroll visible' : ''}`}>
            
            <div className="muertos-header-ctn">
            <h2 className="barrios-header-title">{data?.subtitle}</h2>
                <div className='muertos-mb-20'></div>
                <p>{data?.paragraph}</p>
                <br />
                <p>{data?.paragraph2}</p>
                <br />
                <p>{data?.paragraph3}</p>
            </div>
            
        </div>
        </>
    );
};

export default MuertosInfo;
