import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import './styles.css';



const DescargasHeader = ({ data }) => {
    return (
        <>
      <div className="barrios-header-ctn">
        <h1 className="barrios-header-title">{data?.paginaTitulo}</h1>
        
      </div>
      </>
    );
  };
  
  export default DescargasHeader;