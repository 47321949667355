import React from 'react';
import './styles.css'; // Asegúrate de tener tu archivo de estilos CSS

const MuertosFooter = ({ data }) => {
  return (
    <div className="muertos-footer-container">
      <img
        src={require(`../../assets/img/${data.muertosFooter}`)}
        alt="separador"
        className="muertos-footer-image"
      />
    </div>
  );
};

export default MuertosFooter;
