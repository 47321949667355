import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Context } from '../../Context';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import experiencesEs from '../../assets/data/experiences/es/index.json';
import experiencesEn from '../../assets/data/experiences/en/index.json';
import experiencesFr from '../../assets/data/experiences/fr/index.json';
import experiencesDe from '../../assets/data/experiences/de/index.json';
import experiencesPt from '../../assets/data/experiences/pt/index.json';
import experiencesIt from '../../assets/data/experiences/it/index.json';
import experiencesJp from '../../assets/data/experiences/jp/index.json';
import experiencesCn from '../../assets/data/experiences/cn/index.json';
import ExperienceVideo from '../../components/experienceVideo';
import ExperienceImagesWellness from '../../components/experienceImagesWellness';
import ExperienceCards from '../../components/experienceCards';
import './styles.css';
import MetaTags from '../../components/metaTags';

const ExperienceWellness = () => {
  const { isLanguage } = useContext(Context);
  const [ language, setLanguage ] = useState(experiencesEs);
  const location = useLocation();
  const stateId = location?.pathname?.split('/')[2];
  const getStateInfo = language?.experiences?.filter(experience => experience.id === stateId);
  const data = getStateInfo?.[0];

  window?.scroll({
    top: 0
  });

  useEffect(() => {
    isLanguage === 'ES' ? setLanguage(experiencesEs)
    : isLanguage === 'EN' ? setLanguage(experiencesEn)
    : isLanguage === 'FR' ? setLanguage(experiencesFr)
    : isLanguage === 'DE' ? setLanguage(experiencesDe)
    : isLanguage === 'PT' ? setLanguage(experiencesPt)
    : isLanguage === 'IT' ? setLanguage(experiencesIt)
    : isLanguage === 'JP' ? setLanguage(experiencesJp)
    : isLanguage === 'CN' ? setLanguage(experiencesCn)
    : setLanguage(experiencesEs)
  }, [isLanguage]);

  const metaInfo = {
    'ES': {
      title: 'Experiencias de Bienestar en México',
      description: 'Descubre las mejores experiencias de bienestar en México, desde spas y retiros de yoga hasta tratamientos holísticos. Encuentra tu paz interior en destinos únicos.'
    },
    'EN': {
      title: 'Wellness Experiences in Mexico',
      description: 'Discover the best wellness experiences in Mexico, from spas and yoga retreats to holistic treatments. Find your inner peace in unique destinations.'
    },
    'FR': {
      title: 'Expériences de Bien-Être au Mexique',
      description: 'Découvrez les meilleures expériences de bien-être au Mexique, des spas et retraites de yoga aux traitements holistiques. Trouvez votre paix intérieure dans des destinations uniques.'
    },
    'DE': {
      title: 'Wellness-Erlebnisse in Mexiko',
      description: 'Entdecken Sie die besten Wellness-Erlebnisse in Mexiko, von Spas und Yoga-Retreats bis hin zu ganzheitlichen Behandlungen. Finden Sie Ihre innere Ruhe an einzigartigen Orten.'
    },
    'PT': {
      title: 'Experiências de Bem-Estar no México',
      description: 'Descubra as melhores experiências de bem-estar no México, de spas e retiros de yoga a tratamentos holísticos. Encontre sua paz interior em destinos únicos.'
    },
    'IT': {
      title: 'Esperienze di Benessere in Messico',
      description: 'Scopri le migliori esperienze di benessere in Messico, da spa e ritiri yoga a trattamenti olistici. Trova la tua pace interiore in destinazioni uniche.'
    },
    'JP': {
      title: 'メキシコのウェルネス体験',
      description: 'メキシコでの最高のウェルネス体験をご紹介します。スパやヨガリトリートからホリスティックなトリートメントまで、ユニークな目的地で内なる平和を見つけてください。'
    },
    'CN': {
      title: '墨西哥的健康体验',
      description: '发现墨西哥的最佳健康体验，从水疗和瑜伽疗养到整体疗法。在独特的目的地找到内心的宁静。'
    }
  };

  return (
    <div className="main-content">
      <MetaTags title={metaInfo[isLanguage].title} description={metaInfo[isLanguage].description} />
      <ExperienceVideo data={data} />
      <LazyLoadComponent>
        <ExperienceImagesWellness data={data} />

      </LazyLoadComponent>
    </div>
  );
};

export default ExperienceWellness;
