import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Context } from '../../Context';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import enologiaEs from '../../assets/data/enologia/es/index.json';
import enologiaDe from '../../assets/data/enologia/de/index.json';
import enologiaFr from '../../assets/data/enologia/fr/index.json';
import enologiaEn from '../../assets/data/enologia/en/index.json';
import enologiaIt from '../../assets/data/enologia/it/index.json';
import enologiaPt from '../../assets/data/enologia/pt/index.json';
import enologiaJp from '../../assets/data/enologia/jp/index.json';
import enologiaCn from '../../assets/data/enologia/cn/index.json';
import './styles.css';
import EnologiaBento from '../../components/enologiaBento';
import EnologiaTurismo from '../../components/enologiaTurismo';
import EnologiaInfo from '../../components/enologiaInfo';
import EnologiaDestinos from '../../components/enologiaDestinos';
import EnologiaBanner from '../../components/enologiaBanner';
import EnologiaProduccion from '../../components/enologiaProduccion';
import EnologiaArticulos from '../../components/enologiaArticulos';
import EnologiaDescarga from '../../components/enologiaDescarga';
import CintaRoja from '../../components/cintaRoja';
import MetaTags from '../../components/metaTags';

const Enologia = () => {
    const { isLanguage } = useContext(Context);
    const [language, setLanguage] = useState(enologiaEs);

    window?.scroll({
        top: 0
    });

    useEffect(() => {
        isLanguage === 'ES' ? setLanguage(enologiaEs)
        : isLanguage === 'DE' ? setLanguage(enologiaDe)
        : isLanguage === 'FR' ? setLanguage(enologiaFr)
        : isLanguage === 'EN' ? setLanguage(enologiaEn)
        : isLanguage === 'IT' ? setLanguage(enologiaIt)
        : isLanguage === 'PT' ? setLanguage(enologiaPt)
        : isLanguage === 'JP' ? setLanguage(enologiaJp)
        : isLanguage === 'CN' ? setLanguage(enologiaCn)
            : setLanguage(enologiaEs)
    }, [isLanguage]);

  const metaInfo = {
    'ES': {
      title: 'Enoturismo en México - VisitMexico',
      description: 'Descubre el enoturismo en México. Visita viñedos, disfruta de catas de vino y explora rutas vinícolas.'
    },
    'EN': {
      title: 'Wine Tourism in Mexico - VisitMexico',
      description: 'Discover wine tourism in Mexico. Visit vineyards, enjoy wine tastings, and explore wine routes.'
    },
    'FR': {
      title: 'Œnotourisme au Mexique - VisitMexico',
      description: 'Découvrez l\'œnotourisme au Mexique. Visitez des vignobles, dégustez des vins et explorez les routes des vins.'
    },
    'DE': {
      title: 'Weintourismus in Mexiko - VisitMexico',
      description: 'Entdecken Sie den Weintourismus in Mexiko. Besuchen Sie Weinberge, genießen Sie Weinproben und erkunden Sie Weinstraßen.'
    },
    'PT': {
      title: 'Enoturismo no México - VisitMexico',
      description: 'Descubra o enoturismo no México. Visite vinhedos, aproveite degustações de vinhos e explore rotas vinícolas.'
    },
    'IT': {
      title: 'Enoturismo in Messico - VisitMexico',
      description: 'Scopri l\'enoturismo in Messico. Visita i vigneti, goditi le degustazioni di vino ed esplora le strade del vino.'
    },
    'JP': {
      title: 'メキシコのワインツーリズム - VisitMexico',
      description: 'メキシコのワインツーリズムを発見しよう。ワイナリーを訪れ、ワインのテイスティングを楽しみ、ワインルートを探索しよう。'
    },
    'CN': {
      title: '墨西哥的葡萄酒旅游 - VisitMexico',
      description: '发现墨西哥的葡萄酒旅游。参观葡萄园，享受葡萄酒品鉴，探索葡萄酒路线。'
    }
  };

  return (
        <div className="main-content">
          <MetaTags title={metaInfo[isLanguage].title} description={metaInfo[isLanguage].description} />
            <EnologiaBento data={language} />
            <EnologiaTurismo data={language} />
            <EnologiaInfo data={language} />
            <EnologiaDestinos data={language} />
            <EnologiaBanner data={language} />
            <EnologiaProduccion data={language} />
            <CintaRoja data={language} />
            <EnologiaArticulos data={language} />
            <CintaRoja data={language} />
            <EnologiaDescarga data={language} />
        </div>
    );
};

export default Enologia;
