import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Context } from '../../Context';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

import experienciasRomanceEs from '../../assets/data/romance/es/index.json';
import experienciasRomanceDe from '../../assets/data/romance/de/index.json';
import experienciasRomanceFr from '../../assets/data/romance/fr/index.json';
import experienciasRomanceEn from '../../assets/data/romance/en/index.json';
import experienciasRomanceIt from '../../assets/data/romance/it/index.json';
import experienciasRomancePt from '../../assets/data/romance/pt/index.json';
import experienciasRomanceJp from '../../assets/data/romance/jp/index.json';
import experienciasRomanceCn from '../../assets/data/romance/cn/index.json';
import './styles.css';
import ExperienceVideo from '../../components/experienceVideo';
import MetaTags from '../../components/metaTags';
import TabsComponent from '../../components/experienceInteractive2';
import CintaRoja from '../../components/cintaRoja';

const ExperienciasRomance = () => {
    const { isLanguage } = useContext(Context);
    const [language, setLanguage] = useState(experienciasRomanceEs);

    window?.scroll({
        top: 0
    });

    useEffect(() => {
        isLanguage === 'ES' ? setLanguage(experienciasRomanceEs)
            : isLanguage === 'DE' ? setLanguage(experienciasRomanceDe)
            : isLanguage === 'FR' ? setLanguage(experienciasRomanceFr)
            : isLanguage === 'EN' ? setLanguage(experienciasRomanceEn)
            : isLanguage === 'IT' ? setLanguage(experienciasRomanceIt)
            : isLanguage === 'PT' ? setLanguage(experienciasRomancePt)
            : isLanguage === 'JP' ? setLanguage(experienciasRomanceJp)
            : isLanguage === 'CN' ? setLanguage(experienciasRomanceCn)
            : setLanguage(experienciasRomanceEs)
    }, [isLanguage]);

    const metaInfo = {
      'ES': {
        title: 'Bodas en México: Destinos Románticos y Exclusivos',
        description: 'Descubre las mejores experiencias para bodas en México, desde playas paradisíacas hasta haciendas históricas. Planifica tu boda soñada con nosotros.'
      },
      'EN': {
        title: 'Weddings in Mexico: Romantic and Exclusive Destinations',
        description: 'Discover the best wedding experiences in Mexico, from paradisiacal beaches to historic haciendas. Plan your dream wedding with us.'
      },
      'FR': {
        title: 'Mariages au Mexique : Destinations Romantiques et Exclusives',
        description: 'Découvrez les meilleures expériences de mariage au Mexique, des plages paradisiaques aux haciendas historiques. Planifiez votre mariage de rêve avec nous.'
      },
      'DE': {
        title: 'Hochzeiten in Mexiko: Romantische und Exklusive Ziele',
        description: 'Entdecken Sie die besten Hochzeitserlebnisse in Mexiko, von paradiesischen Stränden bis zu historischen Haciendas. Planen Sie Ihre Traumhochzeit mit uns.'
      },
      'PT': {
        title: 'Casamentos no México: Destinos Românticos e Exclusivos',
        description: 'Descubra as melhores experiências de casamento no México, de praias paradisíacas a haciendas históricas. Planeje seu casamento dos sonhos conosco.'
      },
      'IT': {
        title: 'Matrimoni in Messico: Destinazioni Romantiche ed Esclusive',
        description: 'Scopri le migliori esperienze di matrimonio in Messico, dalle spiagge paradisiache alle storiche haciendas. Pianifica il tuo matrimonio da sogno con noi.'
      },
      'JP': {
        title: 'メキシコの結婚式：ロマンチックで特別な目的地',
        description: 'メキシコでの最高のウェディングエクスペリエンスをご紹介します。楽園のビーチから歴史的なアシエンダまで。夢のウェディングを計画しましょう。'
      },
      'CN': {
        title: '墨西哥婚礼：浪漫独特的目的地',
        description: '发现墨西哥最好的婚礼体验，从天堂般的海滩到历史悠久的庄园。与我们一起策划您的梦想婚礼。'
      }
    };

  return (
        <div className="main-content">
            <MetaTags title={metaInfo[isLanguage].title} description={metaInfo[isLanguage].description} />
            <ExperienceVideo data={language} />
            <LazyLoadComponent>
              <TabsComponent data={language.pestanas} />
            </LazyLoadComponent>
        </div>
    );
};

export default ExperienciasRomance;
