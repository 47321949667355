import React from 'react';
import './styles.css';

const GeoparqueCoahuila = ({ data }) => {
    return (
      <div className="geoparqueSingle-container">
        <h2 className="geoparqueSingle-header-title">{data?.Coahuilatitulo}</h2>
        <p className="geoparqueSingle-paragraph">{data?.Coahuilaparrafo1}</p>
        <p className="geoparqueSingle-paragraph">{data?.Coahuilaparrafo2}</p>
        <p className="geoparqueSingle-paragraph">{data?.Coahuilaparrafo3}</p>
       
      </div>
    );
  };
  
  export default GeoparqueCoahuila;