import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Context } from '../../Context';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import experiencesEs from '../../assets/data/eventosInternacionales/es/index.json';
import experiencesEn from '../../assets/data/eventosInternacionales/en/index.json';
import experiencesFr from '../../assets/data/eventosInternacionales/fr/index.json';
import experiencesDe from '../../assets/data/eventosInternacionales/de/index.json';
import experiencesPt from '../../assets/data/eventosInternacionales/pt/index.json';
import experiencesIt from '../../assets/data/eventosInternacionales/it/index.json';
import experiencesJp from '../../assets/data/eventosInternacionales/jp/index.json';
import experiencesCn from '../../assets/data/eventosInternacionales/cn/index.json';
import InternationalEventsVideo from '../../components/internationalEventsVideo';
import InternationalEventsHeader from '../../components/internationalEventsHeader';
import ExperienceCards from '../../components/experienceCards';
import InternationalEventsSliderVideo from '../../components/internationalEventsSliderVideo';
import InternationalEventsCarouselVideo from '../../components/internationalEventsCarouselVideo';
import InternationalEventsVideoFinder from '../../components/internationalEventsVideoFinder';
import InternationalEventsExplore from '../../components/internationalEventsExplore';
import InternationalEventsVideoGallery from '../../components/internationalEventsVideoGallery';

import HomeSuscribe from '../../components/homeSuscribe';
import './styles.css';
import MetaTags from '../../components/metaTags';

const InternationalEvents = () => {
  const { isLanguage } = useContext(Context);
  const [ language, setLanguage ] = useState(experiencesEs);

  window?.scroll({
    top: 0
  });

  useEffect(() => {
    isLanguage === 'ES' ? setLanguage(experiencesEs)
    : isLanguage === 'EN' ? setLanguage(experiencesEn)
    : isLanguage === 'FR' ? setLanguage(experiencesFr)
    : isLanguage === 'DE' ? setLanguage(experiencesDe)
    : isLanguage === 'PT' ? setLanguage(experiencesPt)
    : isLanguage === 'IT' ? setLanguage(experiencesIt)
    : isLanguage === 'JP' ? setLanguage(experiencesJp)
    : isLanguage === 'CN' ? setLanguage(experiencesCn)
    : setLanguage(experiencesEs)
  }, [isLanguage]);

  const metaInfo = {
    'ES': {
      title: 'Eventos Internacionales en México - VisitMexico',
      description: 'Descubre los eventos internacionales más importantes en México. Planifica tu viaje y vive experiencias únicas.'
    },
    'EN': {
      title: 'International Events in Mexico - VisitMexico',
      description: 'Discover the most important international events in Mexico. Plan your trip and experience unique moments.'
    },
    'FR': {
      title: 'Événements Internationaux au Mexique - VisitMexico',
      description: 'Découvrez les événements internationaux les plus importants au Mexique. Planifiez votre voyage et vivez des expériences uniques.'
    },
    'DE': {
      title: 'Internationale Veranstaltungen in Mexiko - VisitMexico',
      description: 'Entdecken Sie die wichtigsten internationalen Veranstaltungen in Mexiko. Planen Sie Ihre Reise und erleben Sie einzigartige Momente.'
    },
    'PT': {
      title: 'Eventos Internacionais no México - VisitMexico',
      description: 'Descubra os eventos internacionais mais importantes do México. Planeje sua viagem e viva momentos únicos.'
    },
    'IT': {
      title: 'Eventi Internazionali in Messico - VisitMexico',
      description: 'Scopri i più importanti eventi internazionali in Messico. Pianifica il tuo viaggio e vivi esperienze uniche.'
    },
    'JP': {
      title: 'メキシコの国際イベント - VisitMexico',
      description: 'メキシコで最も重要な国際イベントを発見しよう。旅行を計画してユニークな体験を楽しんでください。'
    },
    'CN': {
      title: '墨西哥的国际活动 - VisitMexico',
      description: '发现墨西哥最重要的国际活动。计划您的旅行并体验独特的时刻。'
    }
  };

  return (
    <div className="main-content">
      <MetaTags title={metaInfo[isLanguage].title} description={metaInfo[isLanguage].description} />
      <InternationalEventsVideo data={language} />
      <LazyLoadComponent>
        <InternationalEventsHeader data={language} />

        <InternationalEventsSliderVideo data={language} />
        {/* none */}
        {/*<InternationalEventsCarouselVideo data={language} />*/}
        {/*<InternationalEventsVideoFinder data={language} />*/}
        {/*<InternationalEventsExplore data={language} />*/}
        {/*<InternationalEventsVideoGallery data={language} />*/}
      </LazyLoadComponent>
    </div>
  );
};

export default InternationalEvents;
