import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Context } from '../../Context';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import experiencesEs from '../../assets/data/guiasTuristicas/es/index.json';
import experiencesEn from '../../assets/data/guiasTuristicas/en/index.json';
import experiencesFr from '../../assets/data/guiasTuristicas/fr/index.json';
import experiencesDe from '../../assets/data/guiasTuristicas/de/index.json';
import experiencesPt from '../../assets/data/guiasTuristicas/pt/index.json';
import experiencesIt from '../../assets/data/guiasTuristicas/it/index.json';
import experiencesJp from '../../assets/data/guiasTuristicas/jp/index.json';
import experiencesCn from '../../assets/data/guiasTuristicas/cn/index.json';
import GuiasTuristicasBanner from '../../components/guiasTuristicasBanner';
import GuiasTuristicasContent from '../../components/guiasTuristicasContent';
import HomeSuscribe from '../../components/homeSuscribe';
import './styles.css';
import MetaTags from '../../components/metaTags';

const GuiasTuristicas = () => {
  const { isLanguage } = useContext(Context);
  const [ language, setLanguage ] = useState(experiencesEs);

  window?.scroll({
    top: 0
  });

  useEffect(() => {
    isLanguage === 'ES' ? setLanguage(experiencesEs)
    : isLanguage === 'EN' ? setLanguage(experiencesEn)
    : isLanguage === 'FR' ? setLanguage(experiencesFr)
    : isLanguage === 'DE' ? setLanguage(experiencesDe)
    : isLanguage === 'PT' ? setLanguage(experiencesPt)
    : isLanguage === 'IT' ? setLanguage(experiencesIt)
    : isLanguage === 'JP' ? setLanguage(experiencesJp)
    : isLanguage === 'CN' ? setLanguage(experiencesCn)
    : setLanguage(experiencesEs)
  }, [isLanguage]);

  const metaInfo = {
    'ES': {
      title: 'Guías Turísticas de México: Planifica tu Viaje Perfecto',
      description: 'Encuentra guías turísticas de México para descubrir los mejores destinos, actividades y consejos para planificar tu viaje perfecto con Visit México.'
    },
    'EN': {
      title: 'Mexico Tourist Guides: Plan Your Perfect Trip',
      description: 'Find tourist guides of Mexico to discover top destinations, activities, and tips to plan your perfect trip with Visit Mexico.'
    },
    'FR': {
      title: 'Guides Touristiques du Mexique: Planifiez Votre Voyage Parfait',
      description: 'Trouvez des guides touristiques du Mexique pour découvrir les meilleures destinations, activités et conseils afin de planifier votre voyage parfait avec Visit Mexique.'
    },
    'DE': {
      title: 'Mexiko Reiseführer: Planen Sie Ihre Perfekte Reise',
      description: 'Finden Sie Reiseführer von Mexiko, um Top-Ziele, Aktivitäten und Tipps für Ihre perfekte Reise mit Visit Mexiko zu entdecken.'
    },
    'PT': {
      title: 'Guias Turísticos do México: Planeje sua Viagem Perfeita',
      description: 'Encontre guias turísticos do México para descobrir os principais destinos, atividades e dicas para planejar sua viagem perfeita com Visit México.'
    },
    'IT': {
      title: 'Guide Turistiche del Messico: Pianifica il tuo Viaggio Perfetto',
      description: 'Trova guide turistiche del Messico per scoprire le migliori destinazioni, attività e consigli per pianificare il tuo viaggio perfetto con Visit Messico.'
    },
    'JP': {
      title: 'メキシコ観光ガイド: あなたの完璧な旅行を計画する',
      description: 'メキシコの観光ガイドを見つけて、トップの目的地、アクティビティ、そして完璧な旅行を計画するためのヒントをVisit Mexicoで発見しましょう。'
    },
    'CN': {
      title: '墨西哥旅游指南: 计划您的完美之旅',
      description: '找到墨西哥的旅游指南，发现顶级目的地、活动和计划您的完美之旅的提示。与Visit Mexico一起探索。'
    }
  };

  return (
    <div className="main-content">
      <MetaTags title={metaInfo[isLanguage].title} description={metaInfo[isLanguage].description} />
      <GuiasTuristicasBanner data={language} />
      <LazyLoadComponent>
        <GuiasTuristicasContent data={language} />
      </LazyLoadComponent>
    </div>
  );
};

export default GuiasTuristicas;
