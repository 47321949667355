import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Context } from '../../Context';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

import barriosEs from '../../assets/data/barrios/es/index.json';
import barriosDe from '../../assets/data/barrios/de/index.json';
import barriosFr from '../../assets/data/barrios/fr/index.json';
import barriosEn from '../../assets/data/barrios/en/index.json';
import barriosIt from '../../assets/data/barrios/it/index.json';
import barriosPt from '../../assets/data/barrios/pt/index.json';
import barriosJp from '../../assets/data/barrios/jp/index.json';
import barriosCn from '../../assets/data/barrios/cn/index.json';
import BarriosVideo from '../../components/barriosVideo';
import BarriosHeader from '../../components/barriosHeader';
import './styles.css';
import BarriosCarousel from '../../components/barriosCarousel';
import BarriosHeroSlider from '../../components/barriosHeroSlider';
import BarriosMagicosVideoSlider from '../../components/barriosMagicosVideoSlider';
import MetaTags from '../../components/metaTags';

const Barrios = () => {
    const { isLanguage } = useContext(Context);
    const [language, setLanguage] = useState(barriosEs);

    window?.scroll({
        top: 0
    });

    useEffect(() => {
        isLanguage === 'ES' ? setLanguage(barriosEs)
            : isLanguage === 'DE' ? setLanguage(barriosDe)
            : isLanguage === 'FR' ? setLanguage(barriosFr)
            : isLanguage === 'EN' ? setLanguage(barriosEn)
            : isLanguage === 'IT' ? setLanguage(barriosIt)
            : isLanguage === 'PT' ? setLanguage(barriosPt)
            : isLanguage === 'JP' ? setLanguage(barriosJp)
            : isLanguage === 'CN' ? setLanguage(barriosCn)
            : setLanguage(barriosEs)
    }, [isLanguage]);

    const metaInfo = {
        'ES': {
            title: 'Barrios Mágicos en México - VisitMexico',
            description: 'Explora los Barrios Mágicos de México. Descubre la cultura, historia y encanto único de cada barrio'
        },
        'EN': {
            title: 'Magic Neighborhoods in Mexico - VisitMexico',
            description: 'Explore the Magic Neighborhoods of Mexico. Discover the culture, history and unique charm of each neighborhood'
        },
        'FR': {
            title: 'Quartiers magiques au Mexique - VisitMexico',
            description: 'Explorez les Quartiers Magiques du Mexique. Découvrez la culture, l\'histoire et le charme unique de chaque quartier.">'
        },
        'DE': {
            title: 'Magische Viertel in Mexiko - VisitMexico',
            description: 'Entdecken Sie die magischen Viertel Mexikos. Entdecken Sie die Kultur, Geschichte und den einzigartigen Charme jedes Viertels'
        },
        'PT': {
            title: 'Bairros Mágicos no México - VisitMexico',
            description: 'Explore os Bairros Mágicos do México. Descubra a cultura, história e charme único de cada bairro'
        },
        'IT': {
            title: 'Quartieri magici in Messico - VisitMexico',
            description: 'Esplora i quartieri magici del Messico. Scopri la cultura, la storia e il fascino unico di ogni quartiere'
        },
        'JP': {
            title: 'メキシコのマジカルネイバーフッド - VisitMexico',
            description: 'メキシコのマジカルネイバーフッドを探索しよう。各地区の文化、歴史、ユニークな魅力を発見しよう'
        },
        'CN': {
            title: '墨西哥的魔法街区 - VisitMexico',
            description: '探索墨西哥的魔法街区。发现每个街区的文化、历史和独特魅力。'
        }
    }

    return (
        <div className="main-content">
            <MetaTags title={metaInfo[isLanguage].title} description={metaInfo[isLanguage].description} />
            <BarriosHeroSlider data={language.heroSlide} />
            <LazyLoadComponent>
                <BarriosHeader data={language} />
                <BarriosCarousel data={language.carousels} />
                <BarriosMagicosVideoSlider data={language} />
            </LazyLoadComponent>
        </div>
    );
};

export default Barrios;
