import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Context } from '../../Context';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import experiencesEs from '../../assets/data/experiences/es/index.json';
import experiencesEn from '../../assets/data/experiences/en/index.json';
import experiencesFr from '../../assets/data/experiences/fr/index.json';
import experiencesDe from '../../assets/data/experiences/de/index.json';
import experiencesPt from '../../assets/data/experiences/pt/index.json';
import experiencesIt from '../../assets/data/experiences/it/index.json';
import experiencesJp from '../../assets/data/experiences/jp/index.json';
import experiencesCn from '../../assets/data/experiences/cn/index.json';
import ExperienceVideo from '../../components/experienceVideo';
import ExperienceImagesSustanaible from '../../components/experienceImagesSustanaible';
import ExperienceCards from '../../components/experienceCards';
import './styles.css';
import MetaTags from '../../components/metaTags';

const ExperienceSustainable = () => {
  const { isLanguage } = useContext(Context);
  const [ language, setLanguage ] = useState(experiencesEs);
  const location = useLocation();
  const stateId = location?.pathname?.split('/')[2];
  const getStateInfo = language?.experiences?.filter(experience => experience.id === stateId);
  const data = getStateInfo?.[0];

  window?.scroll({
    top: 0
  });

  useEffect(() => {
    isLanguage === 'ES' ? setLanguage(experiencesEs)
    : isLanguage === 'EN' ? setLanguage(experiencesEn)
    : isLanguage === 'FR' ? setLanguage(experiencesFr)
    : isLanguage === 'DE' ? setLanguage(experiencesDe)
    : isLanguage === 'PT' ? setLanguage(experiencesPt)
    : isLanguage === 'IT' ? setLanguage(experiencesIt)
    : isLanguage === 'JP' ? setLanguage(experiencesJp)
    : isLanguage === 'CN' ? setLanguage(experiencesCn)
    : setLanguage(experiencesEs)
  }, [isLanguage]);

  const metaInfo = {
    'ES': {
      title: 'Experiencias Sostenibles en México',
      description: 'Explora las mejores experiencias sostenibles en México, desde ecoturismo y prácticas responsables hasta conservación del medio ambiente. Descubre cómo contribuir al turismo sostenible.'
    },
    'EN': {
      title: 'Sustainable Experiences in Mexico',
      description: 'Explore the best sustainable experiences in Mexico, from ecotourism and responsible practices to environmental conservation. Discover how to contribute to sustainable tourism.'
    },
    'FR': {
      title: 'Expériences Durables au Mexique',
      description: 'Découvrez les meilleures expériences durables au Mexique, de l\'écotourisme et des pratiques responsables à la conservation de l\'environnement. Découvrez comment contribuer au tourisme durable.'
    },
    'DE': {
      title: 'Nachhaltige Erlebnisse in Mexiko',
      description: 'Erkunden Sie die besten nachhaltigen Erfahrungen in Mexiko, vom Ökotourismus und verantwortungsvollen Praktiken bis zum Umweltschutz. Erfahren Sie, wie Sie zum nachhaltigen Tourismus beitragen können.'
    },
    'PT': {
      title: 'Experiências Sustentáveis no México',
      description: 'Explore as melhores experiências sustentáveis no México, desde o ecoturismo e práticas responsáveis até a conservação ambiental. Descubra como contribuir para o turismo sustentável.'
    },
    'IT': {
      title: 'Esperienze Sostenibili in Messico',
      description: 'Esplora le migliori esperienze sostenibili in Messico, dall\'ecoturismo e dalle pratiche responsabili alla conservazione ambientale. Scopri come contribuire al turismo sostenibile.'
    },
    'JP': {
      title: 'メキシコの持続可能な体験',
      description: 'メキシコでの最高の持続可能な体験を探索します。エコツーリズムや責任ある実践から環境保護まで。持続可能な観光に貢献する方法を発見してください。'
    },
    'CN': {
      title: '墨西哥的可持续体验',
      description: '探索墨西哥最佳的可持续体验，从生态旅游和负责任的实践到环境保护。了解如何为可持续旅游做出贡献。'
    }
  };

  return (
    <div className="main-content">
      <MetaTags title={metaInfo[isLanguage].title} description={metaInfo[isLanguage].description} />
      <ExperienceVideo data={data} />
      <LazyLoadComponent>
        <ExperienceImagesSustanaible data={data} />
      </LazyLoadComponent>
    </div>
  );
};

export default ExperienceSustainable;
