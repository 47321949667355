import React, { useState, useEffect, useContext } from 'react';
import { Context } from '../../Context';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import magicTownsEs from '../../assets/data/magicTowns/es/index.json';
import magicTownsEn from '../../assets/data/magicTowns/en/index.json';
import magicTownsFr from '../../assets/data/magicTowns/fr/index.json';
import magicTownsDe from '../../assets/data/magicTowns/de/index.json';
import magicTownsPt from '../../assets/data/magicTowns/pt/index.json';
import magicTownsIt from '../../assets/data/magicTowns/it/index.json';
import magicTownsJp from '../../assets/data/magicTowns/jp/index.json';
import magicTownsCn from '../../assets/data/magicTowns/cn/index.json';
import MagicTownsVideo from '../../components/magicTownsVideo';
import MagicTownsDescription from '../../components/magicTownsDescription';
import MagicTownsSlider from '../../components/magicTownsSlider';
import MagicTownsKnow from '../../components/magicTownsKnow';
import MagicTownsVideoSlider from '../../components/magicTownsVideoSlider';
import './styles.css';
import MetaTags from '../../components/metaTags';

const MagicTowns = () => {
	const { isLanguage } = useContext(Context);
  const [ language, setLanguage ] = useState(magicTownsEs);

  window?.scroll({
    top: 0
  });

	useEffect(() => {
    isLanguage === 'ES' ? setLanguage(magicTownsEs)
    : isLanguage === 'EN' ? setLanguage(magicTownsEn)
    : isLanguage === 'FR' ? setLanguage(magicTownsFr)
    : isLanguage === 'DE' ? setLanguage(magicTownsDe)
    : isLanguage === 'PT' ? setLanguage(magicTownsPt)
    : isLanguage === 'IT' ? setLanguage(magicTownsIt)
    : isLanguage === 'JP' ? setLanguage(magicTownsJp)
    : isLanguage === 'CN' ? setLanguage(magicTownsCn)
    : setLanguage(magicTownsEs)
  }, [isLanguage]);

  const metaInfo = {
    'ES': {
      title: 'Pueblos Mágicos de México - VisitMexico',
      description: 'Explora los Pueblos Mágicos de México y descubre sus encantos. Vive experiencias inolvidables en cada rincón.'
    },
    'EN': {
      title: 'Magical Towns of Mexico - VisitMexico',
      description: 'Explore Mexico\'s Magical Towns and discover their charms. Experience unforgettable moments in every corner.'
    },
    'FR': {
      title: 'Villes Magiques du Mexique - VisitMexico',
      description: 'Explorez les Villes Magiques du Mexique et découvrez leurs charmes. Vivez des expériences inoubliables à chaque coin.'
    },
    'DE': {
      title: 'Magische Städte in Mexiko - VisitMexico',
      description: 'Erkunden Sie die magischen Städte Mexikos und entdecken Sie ihren Charme. Erleben Sie unvergessliche Momente in jeder Ecke.'
    },
    'PT': {
      title: 'Cidades Mágicas do México - VisitMexico',
      description: 'Explore as Cidades Mágicas do México e descubra seus encantos. Viva momentos inesquecíveis em cada canto.'
    },
    'IT': {
      title: 'Città Magiche del Messico - VisitMexico',
      description: 'Esplora le Città Magiche del Messico e scopri i loro incanti. Vivi momenti indimenticabili in ogni angolo.'
    },
    'JP': {
      title: 'メキシコのマジカルタウン - VisitMexico',
      description: 'メキシコのマジカルタウンを探索し、その魅力を発見しよう。すべての場所で忘れられない瞬間を体験してください。'
    },
    'CN': {
      title: '墨西哥的魔法小镇 - VisitMexico',
      description: '探索墨西哥的魔法小镇，发现它们的魅力。在每个角落体验难忘的时刻。'
    }
  };

  return (
    <div className="main-content">
      <MetaTags title={metaInfo[isLanguage].title} description={metaInfo[isLanguage].description} />
      <MagicTownsVideo data={language} />
			<LazyLoadComponent>
				<MagicTownsDescription data={language} />
				<MagicTownsSlider data={language} />
				<MagicTownsKnow data={language} />
				<MagicTownsVideoSlider data={language} />
			</LazyLoadComponent>
    </div>
  );
};

export default MagicTowns;
