import {Helmet} from "react-helmet";

const MetaTags = ({title, description, lang}) => {
    return (
      <>
          <Helmet>
              <html lang={lang}/>
              <title>{title}</title>
              <meta charSet="UTF-8"/>
              <meta name="description" content={description}/>
          </Helmet>
      </>
    );
}

export default MetaTags;
