import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Context } from '../../Context';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import destinationsEs from '../../assets/data/destinations/es/index.json';
import destinationsEn from '../../assets/data/destinations/en/index.json';
import destinationsFr from '../../assets/data/destinations/fr/index.json';
import destinationsDe from '../../assets/data/destinations/de/index.json';
import destinationsPt from '../../assets/data/destinations/pt/index.json';
import destinationsIt from '../../assets/data/destinations/it/index.json';
import destinationsJp from '../../assets/data/destinations/jp/index.json';
import destinationsCn from '../../assets/data/destinations/cn/index.json';
import DestinationVideo from '../../components/destinationVideo';
import DestinationKnown from '../../components/destinationKnow';
import DestinationSlider from '../../components/destinationSlider';
import DestinationDescription from '../../components/destinationDescription';
import DestinationSpotify from '../../components/destinationSpotify';
import DestinationVideo360 from '../../components/destinationVideo360';
import './styles.css';
import MetaTags from '../../components/metaTags';

const Destination = () => {
  const { isLanguage } = useContext(Context);
  const [ language, setLanguage ] = useState(destinationsEs);
  const location = useLocation();
  const stateId = location?.pathname?.split('/')[2];
  const getStateInfo = language?.states?.filter(state => state.id === stateId);
  const data = getStateInfo?.[0];

  window?.scroll({
    top: 0
  });

  useEffect(() => {
    isLanguage === 'ES' ? setLanguage(destinationsEs)
    : isLanguage === 'EN' ? setLanguage(destinationsEn)
    : isLanguage === 'FR' ? setLanguage(destinationsFr)
    : isLanguage === 'DE' ? setLanguage(destinationsDe)
    : isLanguage === 'PT' ? setLanguage(destinationsPt)
    : isLanguage === 'IT' ? setLanguage(destinationsIt)
    : isLanguage === 'JP' ? setLanguage(destinationsJp)
    : isLanguage === 'CN' ? setLanguage(destinationsCn)
    : setLanguage(destinationsEs)
  }, [isLanguage]);

  const metaInfo = {
    'ES': {
      title: 'Destinos Turísticos de México: Descubre la Diversidad',
      description: 'Explora los destinos turísticos de México y descubre su diversidad cultural, natural e histórica con Visit México.'
    },
    'EN': {
      title: 'Tourist Destinations in Mexico: Discover Diversity',
      description: 'Explore Mexico\'s tourist destinations and discover its cultural, natural, and historical diversity with Visit Mexico.'
    },
    'FR': {
      title: 'Destinations Touristiques au Mexique: Découvrez la Diversité',
      description: 'Explorez les destinations touristiques du Mexique et découvrez sa diversité culturelle, naturelle et historique avec Visit Mexique.'
    },
    'DE': {
      title: 'Touristische Ziele in Mexiko: Entdecken Sie die Vielfalt',
      description: 'Erkunden Sie die touristischen Ziele in Mexiko und entdecken Sie seine kulturelle, natürliche und historische Vielfalt mit Visit Mexiko.'
    },
    'PT': {
      title: 'Destinos Turísticos no México: Descubra a Diversidade',
      description: 'Explore os destinos turísticos no México e descubra sua diversidade cultural, natural e histórica com Visit México.'
    },
    'IT': {
      title: 'Destinazioni Turistiche in Messico: Scopri la Diversità',
      description: 'Esplora le destinazioni turistiche in Messico e scopri la sua diversità culturale, naturale e storica con Visit Messico.'
    },
    'JP': {
      title: 'メキシコの観光地: 多様性を発見する',
      description: 'Visit Mexicoと一緒にメキシコの観光地を探索し、その文化的、自然的、歴史的な多様性を発見しましょう。'
    },
    'CN': {
      title: '墨西哥旅游目的地: 发现多样性',
      description: '与Visit Mexico一起探索墨西哥的旅游目的地，发现其文化、自然和历史的多样性。'
    }
  };

  return (
    <div className="main-content">
      <MetaTags title={metaInfo[isLanguage].title} description={metaInfo[isLanguage].description} />
      <DestinationVideo data={data} />
      <LazyLoadComponent>
        <DestinationKnown data={data} />
        <DestinationSlider data={data} />
        <DestinationDescription data={data} />
        <DestinationSpotify data={data} />
        <DestinationVideo360 data={data} />
      </LazyLoadComponent>
    </div>
  )
};

export default Destination;
