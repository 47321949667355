import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "./styles.css"; // Aquí pondrás los estilos con el prefijo "muertos_"

const MuertosEventos = ({ data }) => {
  return (
    <div className="muertos_container">
      {/* Mostrar el título antes de los cards */}
      <h2 className="barrios-header-title">{data.eventosTitle}</h2>

      <div className="muertos_row">
        {data.eventos.map((evento, index) => (
          <div className="muertos_col" key={index}>
            <div className="muertos_card">
              <LazyLoadImage
                src={require(`../../assets/img/muertos/${evento.image}`)}
                alt={evento.title}
                className="muertos_card-image"
              />
              <div className="muertos_card-info">
                <div className="muertos_card-date">
                  <span>{evento.month}</span>
                  <br />
                  <span>{evento.day}</span>
                </div>
                <div className="muertos_card-details">
                  <h3 className="muertos_card-title">{evento.title}</h3>
                  <p className="muertos_card-description">{evento.description}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MuertosEventos;