import React from 'react';
import './styles.css';

const GeoParquesInfo = ({ data }) => {
    return (
        <>
      <div className="geoparque-header-ctn">
        <h2 className="geoparque-header-title">{data?.queEsTitle}</h2>
        <p>{data?.queEsParrafo}</p>
      </div>
      
      </>
    );
  };
  
  export default GeoParquesInfo;